let doctorsState

if (window.localStorage.getItem('doctors')) {
  doctorsState = JSON.parse(window.localStorage.getItem('doctors'))
} else {
  doctorsState = null
}

export function doctorsReducer (state = doctorsState, action) {
  switch (action.type) {
    case 'NEW_DOCTORS':
      return action.payload

    case 'CLEAR_DOCTORS':
      return action.payload

    default:
      return state
  }
}
