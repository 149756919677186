import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import CreateUserForm from '../../forms/CreateUserForm/CreateUserForm'
import './CreateUserModal.css'

function CreateUserModal ({ modal, createUsr, modalState, loading }) {
  const client = useSelector((state) => state.client)
  const [newUser, setNewUser] = useState({
    email: '',
    firstName: '',
    lastName: '',
    clientId: client._id,
    role: 'MAINTAINER',
    status: 'PENDING'
  })

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              Crea un nuevo usuario
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            <CreateUserForm user={newUser} setUser={setNewUser} handleChange={handleChange} />

            <div className='done-container'>
              <div className='center-btn'>
                {!loading
                  ? (
                    <button
                      type='button'
                      className='btn main-btn'
                      onClick={async () => await createUsr(newUser, setNewUser)}
                      disabled={
                    newUser.email === '' ||
                    newUser.firstName === '' ||
                    newUser.lastName === ''
                  }
                    >
                      Listo
                    </button>
                    )
                  : (<LoadingBtn isLoading={loading} text='Guardando...' />)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateUserModal
