import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import RadioBtn from '../../buttons/RadioBtn/RadioBtn'
import { deleteCard, selectDefaultCard } from '../../../actions/paymentSource'
import { titleCase } from '../../../helpers/style_string.helper'
import { imageSrc } from '../../../helpers/transaction.helper'
import './CardsCard.css'

function CardsCard ({
  card,
  cards,
  setCards,
  selectedCardId,
  setSelectedCardId,
  dispatch,
  saveCardLocally = () => {},
  modalState,
  setSelectedPaymentCard = () => {},
  fromModal = false
}) {
  const location = useLocation()
  const clientId = useSelector((state) => state.client?._id)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  async function handleRadioBtnClick (event, card) {
    try {
      const res = await selectDefaultCard(user, token, clientId, selectedCardId, card._id)
      if (res.data) {
        setSelectedCardId(event.target.value)
        card.default = true
        saveCardLocally(card)
        if (location.pathname === '/payment') {
          setSelectedPaymentCard(card._id)
          if (fromModal) modalState.hide()
        }
        if (location.pathname === '/profile' || fromModal) toast.success('Tu tarjeta predeterminada fue cambiada.')
      }
    } catch (error) {
      console.log(error)
      toast.error('Ocurrió un error al seleccionar la tarjeta, por favor intenta de nuevo.')
    }
  }

  async function handleDelete (card) {
    if (
      !window.confirm(
        '¿Estas seguro que quieres eliminar esta tarjeta? Esta acción no se puede deshacer.'
      )
    ) { return }

    try {
      const { data } = await deleteCard(user, token, clientId, card.payment_source_id)
      if (data) setCards(data.cards)

      const cardsFromDB = data.cards
      if (cardsFromDB.length === 0) {
        setSelectedCardId('')
        setCards([])
        deleteCardLocally()
      } else {
        setCards(cardsFromDB)
        setSelectedCardId(cardsFromDB[0]?._id)
        saveCardLocally({ ...cardsFromDB[0], default: true })
        await selectDefaultCard(user, token, clientId, selectedCardId, cardsFromDB[0]?._id)
      }
    } catch (error) {
      toast.error('No se pudo eliminar la tarjeta')
    }
  }

  function deleteCardLocally (c) {
    window.localStorage.removeItem('card', JSON.stringify(c))
    dispatch({
      type: 'LOGOUT_CARD',
      payload: null
    })
  }

  return card && (
    <div className={'cards-card' + (location.pathname === '/payment' ? ' payment-card' : '')}>
      <div className='card-data row'>
        <div className='card-radio-btn col-1'>
          <RadioBtn name={'radio-card' + (fromModal ? ' modal' : '')} value={card?._id} selectedId={selectedCardId} handleClick={(event) => handleRadioBtnClick(event, card)} />
        </div>
        <div className='card-info col-10 row'>
          {card?.brand && (
            <div className='card-brand-img col-1'>
              <img className='brand-img' src={imageSrc(card.brand.toLowerCase())} alt='metodo-pago' />
            </div>
          )}
          <div className='card-main-info col-7'>
            <div className='card-number'>
              <p className='paragraphs'>**** **** **** {card.last_four}</p>
            </div>
            <div className='card-name'>
              <p className='small-texts'>{titleCase(card.card_holder)}</p>
            </div>
          </div>
          <div className='card-dates col-2 justify-center'>
            <div className='card-date'>
              <p className='small-texts'>{card.exp_month + '/' + card.exp_year}</p>
            </div>
          </div>
        </div>
        {location.pathname === '/profile' &&
            (
              <div className='card-action col-1'>
                <button className='btn delete-btn' onClick={async () => handleDelete(card)}>
                  <i className='fas fa-trash' />
                </button>
              </div>
            )}
      </div>
    </div>
  )
}

export default CardsCard
