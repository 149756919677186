import clients from './clients'

export const campaigns = {
  [clients.PAULINA_ZARRABE]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    },
    {
      value: '6 month',
      text: 'Recordatorio 6 meses'
    }
  ],
  [clients.CASA_DE_SONRISAS]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    },
    {
      value: '6 month',
      text: 'Recordatorio 6 meses'
    }
  ],
  [clients.KI_PLANNER]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    },
    {
      value: '6 month',
      text: 'Recordatorio 6 meses'
    }
  ],
  [clients.URO_SERVICE]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    },
    {
      value: 'recommendations',
      text: 'Recomendaciones'
    },
    {
      value: 'evaluation',
      text: 'Evaluaciones'
    }
  ],
  [clients.JUAN_PABLO_GONZALEZ]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    },
    {
      value: 'marketing',
      text: 'Marketing'
    }
  ],
  [clients.WAKEUP]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'confirmation_dr',
      text: 'Confirmación doctor'
    }
  ],
  [clients.DERMATOLOGICA]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: 'scheduling',
      text: 'Agendamiento'
    }
  ],
  [clients.DEFAULT]: [
    {
      value: 'confirmation',
      text: 'Confirmación de citas'
    },
    {
      value: '6 month',
      text: 'Recordatorio 6 meses'
    }
  ]
}
