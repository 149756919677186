import React from 'react'
import { roles } from '../../../constants/roles'

function RolePicker ({ value, onChange }) {
  return (
    <select
      className='form-control form-select'
      value={value}
      onChange={onChange}
    >
      <option value='MAINTAINER'>{roles.MAINTAINER}</option>
      <option value='VIEWER'>{roles.VIEWER}</option>
    </select>
  )
}

export default RolePicker
