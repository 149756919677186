import { Link } from 'react-router-dom'
import './LegalCheckbox.css'

function LegalCheckbox ({ checkbox, handleCheckboxClick }) {
  return (
    <div className='checkbox d-flex'>
      <label className='legal-checkbox checkbox-container'>
        Acepto la{' '}
        <Link
          className='text-nowrap'
          to='/legal'
          state={1}
        >
          Política de Privacidad
        </Link>{' '}
        y los{' '}
        <Link
          className='text-nowrap'
          to='/legal'
          state={2}
        >
          Términos y Condiciones
        </Link>
        <input
          type='checkbox'
          value={checkbox}
          name='legal'
          onChange={handleCheckboxClick}
        />
        <span className='checkmark' />
      </label>
    </div>
  )
}

export default LegalCheckbox
