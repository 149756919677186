let cardState

if (window.localStorage.getItem('card')) {
  cardState = JSON.parse(window.localStorage.getItem('card'))
} else {
  cardState = null
}

export function cardReducer (state = cardState, action) {
  switch (action.type) {
    case 'USER_CARD':
      return { ...state, ...action.payload }

    case 'LOGOUT_CARD':
      return action.payload

    default:
      return state
  }
};
