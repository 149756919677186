import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import userIcon from '../../../assets/user-icon.svg'
import './ProfileBtn.css'

function ProfileBtn () {
  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const location = useLocation()

  function updateLocalStorage (objToUpdate, type) {
    window.localStorage.removeItem(objToUpdate)
    dispatch({
      type,
      payload: null
    })
  }

  function logout () {
    updateLocalStorage('client', 'LOGOUT_CLIENT')
    updateLocalStorage('user', 'LOGOUT_USER')
    updateLocalStorage('token', 'LOGOUT_TOKEN')
    updateLocalStorage('table', 'CLEAR_TABLE')
    updateLocalStorage('doctors', 'CLEAR_DOCTORS')
  }

  return (
    <div className='profile-btn'>
      <hr />
      <div className='dropdown'>
        <button className='dropbtn'>
          <img
            className='user-icon'
            src={userIcon}
            alt='user-icon'
          />
          {user.firstName + ' ' + user.lastName}
        </button>
        <div className='dropdown-content'>
          <div className='dropdown-container'>
            {location.pathname === '/main' && <a href='/profile'>Mi Perfil</a>}
            {location.pathname === '/profile' && <a href='/main'>Inicio</a>}
            <button onClick={logout}>Cerrar Sesión</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfileBtn
