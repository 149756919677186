import React from 'react'
import './UsageCard.css'

function UsageCard ({ title, value, total, icon, color }) {
  function getPercentage () {
    return parseInt((value / total) * 100)
  }

  return (
    <div className='col-md-3'>
      <div className={`usage-card ${color && color + '-card'}`}>
        <div className='row usage-card-row'>
          <div className='col-7'>
            <h3 className={(color && color !== 'total') ? 'dark-txt' : undefined}>{value}</h3>
          </div>
          {total &&
            <div className='col-4'>
              <p className='dark-txt'>{getPercentage() + '%'}</p>
            </div>}
        </div>
        <div className='row usage-card-row lower-row'>
          <div className='col-7'>
            <h5 className='dark-txt text-nowrap'>{title}</h5>
          </div>
          {icon &&
            <div className='col-3'>
              <p><i className={'fas ' + icon} /></p>
            </div>}
        </div>
      </div>
    </div>
  )
}

export default UsageCard
