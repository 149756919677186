import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { updateUserAndClient } from '../../../actions/user'
import RegisterClientForm from '../RegisterClientForm/RegisterClientForm'
import RegisterUserForm from '../RegisterUserForm/RegisterUserForm'
import './PreferencesForm.css'

function PreferencesForm () {
  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)
  const dispatch = useDispatch()
  const [userState, setUserState] = useState(user)
  const [clientState, setClientState] = useState(client)

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  function handleChangeUser (event) {
    const { name, value } = event.target

    setUserState((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function handleChangeClient (event) {
    const { name, value } = event.target

    setClientState((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      const res = await updateUserAndClient(userState, clientState, token)
      if (res.data) {
        const { user: u, token: t, client: c } = res.data
        updateLocalStorage(u, 'user', 'LOGGED_IN_USER')
        updateLocalStorage(t, 'token', 'LOGGED_IN_TOKEN')
        updateLocalStorage(c, 'client', 'LOGGED_IN_CLIENT')
        toast.success('El usuario y cliente fueron actualizados correctamente.')
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  return (
    <form
      className={
      user.role === 'ADMIN'
        ? 'preferences form'
        : 'preferences form not-admin'
      }
      onSubmit={handleSubmit}
    >
      {window.outerWidth > 1028 && <h1 className='information-title'>Preferencias</h1>}
      <div className='row d-flex'>
        <div className='col-md-6'>
          <h4>Usuario</h4>
          <div className='form-floating'>
            <input
              name='email'
              type='email'
              className='form-control'
              id='floatingEmail'
              placeholder='Correo Electrónico'
              value={userState.email}
              onChange={handleChangeUser}
              disabled
            />
            <label htmlFor='floatingEmail'>Correo Electrónico</label>
          </div>
          <RegisterUserForm user={userState} handleChange={handleChangeUser} />
        </div>
        {user.role === 'ADMIN' && (
          <div className='col-md-6'>
            <h4>Empresa</h4>
            <RegisterClientForm client={clientState} handleChangeV2={handleChangeClient} />
          </div>
        )}
      </div>
      {window.outerWidth > 1028 && user.role === 'ADMIN' && <hr />}
      <button type='submit' className='btn main-btn'>Actualizar</button>
    </form>
  )
}

export default PreferencesForm
