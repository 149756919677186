import React from 'react'
import './ProfileContentBtn.css'

function ProfileContentBtn ({ selectedBtn, value, text, handleClick }) {
  return (
    <button
      className={selectedBtn === value ? 'btn profile-content-btn selected' : 'btn profile-content-btn'}
      value={value}
      onClick={handleClick}
    >
      {text}
    </button>
  )
}

export default ProfileContentBtn
