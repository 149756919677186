import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { updatePassword } from '../../../actions/user'
import PasswordInput from '../../inputs/PasswordInput/PasswordInput'
import './PasswordForm.css'

function PasswordForm () {
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [passwordData, setPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    _id: user._id,
    clientId: user.clientId
  })

  function togglePasswordVisiblity () {
    setVisiblePassword(!visiblePassword)
  }

  function handleChange (event) {
    const { name, value } = event.target

    setPasswordData((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      const res = await updatePassword(user, passwordData, token)
      if (res.data) {
        setPasswordData(prevData => ({ ...prevData, oldPassword: '', newPassword: '' }))
        toast.success('Contraseña modificada con éxito.')
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  return (
    <div className='security'>
      {window.outerWidth > 1028 && <h1 className='information-title'>Seguridad</h1>}

      <form className='form password-form' onSubmit={handleSubmit}>
        <h4>Modificar contraseña</h4>

        <PasswordInput
          visiblePassword={visiblePassword}
          togglePasswordVisiblity={togglePasswordVisiblity}
          handleChange={handleChange}
          value={passwordData.oldPassword}
          name='oldPassword'
          text='Contraseña actual'
        />

        <PasswordInput
          visiblePassword={visiblePassword}
          togglePasswordVisiblity={togglePasswordVisiblity}
          handleChange={handleChange}
          value={passwordData.newPassword}
          name='newPassword'
          text='Nueva contraseña'
        />

        <button className='btn main-btn' type='submit'>Modificar</button>
      </form>
    </div>
  )
}

export default PasswordForm
