import axios from 'axios'
import { setConfig } from '../helpers/set_config.helper'

export async function getPaymentSources (user, token, clientId) {
  const config = await setConfig(user, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}payment-source/${clientId}`, config)
}

export async function getPresignedAcceptanceToken () {
  const url = process.env.NODE_ENV === 'production'
    ? 'https://production.wompi.co'
    : 'https://sandbox.wompi.co'
  const key = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_WOMPI_KEY_PROD
    : process.env.REACT_APP_WOMPI_KEY_DEV
  return await axios.get(`${url}/v1/merchants/${key}`)
}

export async function saveNewCard (user, token, wompiCard) {
  const config = await setConfig(user, token)
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}payment-source/save-card`, wompiCard, config)
}

export async function deleteCard (user, token, clientId, paymentSourceId) {
  const config = await setConfig(user, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}payment-source/delete-card`, { clientId, paymentSourceId }, config)
}

export async function selectDefaultCard (user, token, clientId, oldCardId, newCardId) {
  const config = await setConfig(user, token)
  return await axios.patch(process.env.REACT_APP_CONFIRMATION_API + 'payment-source/default', { clientId, oldCardId, newCardId }, config)
}
