module.exports = Object.freeze({
  PAULINA_ZARRABE: '6398b3b07c3882dfbfc6f482',
  CASA_DE_SONRISAS: '63f3d0bde7c25ae5d525acc0',
  KI_PLANNER: '639f8a9e156532e4e9e716f3',
  URO_SERVICE: '646e30d91f2447dec2e04e33',
  JUAN_PABLO_GONZALEZ: '64ff3fe6a7b51ebf80131024',
  WAKEUP: '652975d69f154e8687b23c79',
  DERMATOLOGICA: '664ba6ddba5bfe4b4afc42ad',
  DEFAULT: 'Default'
})
