import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { recoverPassword } from '../../actions/user'
import LoadingBtn from '../../components/buttons/LoadingBtn/LoadingBtn'

import Footer from '../../components/Footer/Footer'

function PasswordRecovery () {
  const [email, setEmail] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit (e) {
    e.preventDefault()
    setIsLoading(true)
    try {
      const res = await recoverPassword(email)
      if (res.data) {
        setIsSent(true)
        toast.success('Correo enviado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setIsLoading(false)
  }

  return (
    <div className='login'>
      <div className='login-container container-fluid'>
        <form className='form login-form' onSubmit={handleSubmit}>
          {!isSent
            ? (
              <>
                <h2>Recupera tu cuenta</h2>
                <div className='form-floating'>
                  <input
                    type='email'
                    className='form-control'
                    id='floatingEmail'
                    placeholder='Correo Electrónico'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                  />
                  <label htmlFor='floatingEmail'>Correo Electrónico</label>
                </div>
                {!isLoading
                  ? <button type='submit' className='btn main-btn' disabled={!email}>Enviar Correo</button>
                  : <LoadingBtn isLoading={isLoading} text='Enviando...' />}
              </>
              )
            : (
              <>
                <h2>Correo enviado</h2>
                <div>
                  <p>Enviamos un email al correo vinculado a tu cuenta. Si no lo encuentras, revisa la pestaña de spam.</p>
                </div>

                {!isLoading
                  ? <button type='submit' className='btn main-btn' disabled={!email}>Reenviar correo</button>
                  : <LoadingBtn isLoading={isLoading} text='Reenviando...' />}
              </>
              )}
        </form>
        <Footer />
      </div>
    </div>
  )
}

export default PasswordRecovery
