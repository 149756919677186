import { useLocation } from 'react-router-dom'

import kiFooter from '../../assets/ki-footer.svg'
import './Footer.css'

function Footer () {
  const location = useLocation()
  const year = new Date().getFullYear()

  return (
    <footer>
      <img src={kiFooter} className='ki-footer' alt='ki-footer' />
      {location.pathname !== '/main' && <p>Copyright Ki ⓒ {year}</p>}
    </footer>
  )
}

export default Footer
