import { Modal } from 'bootstrap'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { createNewUser, deleteUser, getUsersByClient, updateUserRole } from '../../../actions/user'
import { formatRole, formatStatus } from '../../../helpers/table.helper'
import CreateUserModal from '../../modals/CreateUserModal/CreateUserModal'
import RolePicker from '../../pickers/RolePicker/RolePicker'
import BaseTable from '../BaseTable/BaseTable'
import './UsersTable.css'

function UsersTable () {
  const location = useLocation()

  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const header = useRef(['Correo', 'Nombre', 'Rol', 'Estado'])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const calledUsers = useRef(false)

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    let isActive = true
    if (isActive && !calledUsers.current) {
      calledUsers.current = true
      getUsers()
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  async function getUsers () {
    try {
      const res = await getUsersByClient(user, client._id, token)
      if (res && res?.data && res.data.length > 0) setUsers(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  async function createUsr (newUser, setNewUser) {
    setLoading(true)
    try {
      const res = await createNewUser(newUser, user, token)
      modalState.hide()
      if (res.data) {
        setUsers(prevData => [...prevData, res.data])
        setNewUser(prevData => ({ ...prevData, email: '', firstName: '', lastName: '' }))
        toast.success('Usuario agregado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function deleteUsr (data) {
    if (
      !window.confirm(
        '¿Estas seguro que quieres eliminar este usuario? Esta acción no se puede deshacer.'
      )
    ) { return }

    const foundUser = users.find((u) => u.email === data.email)
    if (foundUser !== '') {
      try {
        const { data } = await deleteUser(foundUser, user, token)
        if (data) {
          const newUserArray = users.filter((u) => u.email !== data.email)
          setUsers(newUserArray)
          toast.success('Usuario eliminado con éxito.')
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  async function onRoleChange (e, i, u) {
    setUsers(prevData => {
      const newData = [...prevData]
      newData[i].role = e.target.value
      return newData
    })
    try {
      await updateUserRole(u, user, token)
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  function formatUsersJson (resUsers) {
    const formattedUsers = []
    // Only admins can edit other users
    if (user.role === 'ADMIN' && !header.current.includes('Opciones')) {
      header.current.push('Opciones')
    }
    resUsers.forEach((u) => {
      const formattedUser = {
        email: u.email,
        name: `${u.firstName} ${u.lastName}`,
        role: formatRole(u.role),
        status: formatStatus(u.status)
      }

      if (user.role === 'ADMIN') {
        formattedUser.role = u.role === 'ADMIN'
          ? u.email.includes('bot') ? 'Bot' : 'Administrador'
          : '<select id="role"></select>'
        formattedUser.options = u.role === 'ADMIN'
          ? (u.email.includes('bot') ? '<button id="bot">Bot</button>' : '<button id="you">Eres tu</button>')
          : '<button id="delete">Eliminar</button>'
      }

      formattedUsers.push(formattedUser)
    })
    return formattedUsers
  }

  function formatRowData (rowData, row, i) {
    if (user.role === 'ADMIN' &&
    location.pathname === '/profile' &&
     rowData !== null &&
     rowData !== undefined
    ) {
      if (rowData.includes('<button')) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs.id === 'you') {
              return <button className='btn main-btn text-nowrap' disabled>Eres tu</button>
            }
            if (attribs && attribs.id === 'bot') {
              return <button className='btn main-btn text-nowrap' disabled>Bot</button>
            }
            if (attribs && attribs.id === 'delete') {
              return <button onClick={async () => await deleteUsr(row)} className='btn main-btn text-nowrap'>Eliminar</button>
            }
          }
        })
      } else if (rowData.includes('<select') && users.length > 0) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs?.id === 'role' && users[i]?.role) {
              return (
                <RolePicker
                  value={users[i].role}
                  onChange={async (e) => await onRoleChange(e, i, users[i])}
                />
              )
            }
          }
        })
      }
    }

    return rowData
  }

  return (
    <div className='users'>
      <CreateUserModal modal={modal} createUsr={createUsr} modalState={modalState} loading={loading} />

      {window.outerWidth > 1028 && <h1 className='information-title'>Usuarios</h1>}
      <div className='users-container'>
        {user.role === 'ADMIN'
          ? (
            <div className='row d-flex'>
              <div className='col-md-9 title-col-1'>
                <p>
                  Aqui puedes ver a los usuarios que tienen acceso a esta cuenta.
                  Eres el propietario y solo tu puedes agregar, editar y eliminar a los usuarios que se encuentran debajo.
                </p>
              </div>
              <div className='col-md-3 title-col-2'>
                <button className='btn main-btn' onClick={() => modalState.show()}>Agregar nuevo usuario</button>
              </div>
            </div>
            )
          : (
            <div className='row d-flex'>
              <div className='col-md-12 title-col-1'>
                <p>
                  Aqui puedes ver a los usuarios que tienen acceso a esta cuenta.
                  Solo el propietario de la cuenta puede agregar, editar y eliminar a los usuarios que se encuentran debajo.
                </p>
              </div>
            </div>
            )}

        <BaseTable
          json={formatUsersJson(users)}
          header={header.current}
          formatRowData={formatRowData}
        />
      </div>
    </div>
  )
}

export default UsersTable
