import _ from 'lodash'

import { checkNum, strToTime, verifyNum } from '../style_string.helper'
import DefaultFormat from './default_format.helper'

export default class CasaDeSonrisasFormat extends DefaultFormat {
  formatTimeCSV () {
    // Turn time into military time
    let { time } = this.jsonObj
    if (time === '') return ''
    // Check if time has letters
    const regExp = /[a-zA-Z]/g
    if (regExp.test(time)) {
      // Time has letters
      const [t, ap] = time
        .toUpperCase()
        .trim()
        .replace(' ', '')
        .replace('. ', '')
        .replaceAll('.', '') // Remove dots
        .replace(/\s+/g, ' ') // Replace multiple spaces with one
        .replace(/(?:\\[rn]|[\r\n])/g, '') // Remove new lines
        .split(' ')
      const aux = t.slice(0, -3)
      time = `${aux} ${ap}`
    } else {
      // Time doesn't have letters
      time = strToTime(time)
    }
    const ampm = time.split(' ')[1]
    const hour = time.split(':')[0]
    if ((hour < 8 && ampm === 'AM') ||
      (hour === 12 && ampm === 'PM') ||
      (hour > 5 && ampm === 'PM')) return ''
    return time
  }

  async formatNumberCSV () {
    let { number2 } = this.jsonObj
    if (number2.length < 10) throw new Error(`El usuario ${this.jsonObj.name} tiene un celular inválido.`)
    if (number2.length === 21 && number2.split(' ').length === 2) number2 = number2.split(' ')[0]
    if (number2 !== undefined) {
      let n2 = number2.replace(/[^0-9]/g, '')
      if (n2.length === 20) n2 = n2.slice(10, 20)
      if (checkNum(n2)) return await verifyNum(n2, this.jsonObj)
      else return ''
    } else {
      throw new Error('No se encontraron los campos de número.')
    }
  }

  formatFinalObj (obj) {
    const finalObj = _.omit(obj, [
      'agenda',
      'assistence',
      'confirm',
      'creationDate',
      'delay',
      'duration',
      'number1',
      'number2',
      'subject'
    ])

    return finalObj
  }
}
