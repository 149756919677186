import React from 'react'
import './BaseTableFilters.css'

function BaseTableFilters ({ column, options, selectedOptions, onFilterChange, isOpen, index, header }) {
  const handleOptionChange = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option]
    onFilterChange(column, newSelectedOptions)
  }

  return isOpen && (
    <div className='filter-table-dropdown'>
      <div className='dropdown-table-menu scroll'>
        {options.map((option) => (
          <div key={option} className='filter-div' onClick={() => handleOptionChange(option)}>
            <input
              type='checkbox'
              checked={
                  selectedOptions.length === 0
                    ? option === 'Seleccionar todos' && !selectedOptions.includes('Seleccionar todos')
                    : selectedOptions.includes(option)
                }
              onChange={() => handleOptionChange(option)}
              className='checkbox'
            />
            <label className='form-check-label' htmlFor='flexCheckDefault'>
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default BaseTableFilters
