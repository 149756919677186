import {
  checkNum,
  titleCase,
  verifyNum
} from '../style_string.helper'

export default class DefaultFormat {
  constructor (jsonObj, doctors, campaignState, optionalParameter) {
    this.jsonObj = jsonObj
    this.doctors = doctors
    this.campaignState = campaignState
    this.optionalParameter = optionalParameter
  }

  async formatNumberCSV () {
    const { number } = this.jsonObj
    if (this.jsonObj?.number === undefined) throw new Error('No se encontró el campo de número.')
    if (number.length === 0) return ''
    const n = number.replaceAll(' ', '')
    try {
      if (checkNum(n)) return await verifyNum(n, this.jsonObj)
      return ''
    } catch (error) {
      if (error?.response?.data !== null && parseInt(error?.response?.data.errors[0].code) === 21) {
        if (this.jsonObj.name1 === undefined || this.jsonObj.lastName1 === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene un número válido, pero no es un número de celular.`)
        throw new Error(`El usuario ${this.jsonObj.name1} ${this.jsonObj.lastName1} tiene un número inválido.`)
      }
      throw new Error(error.message)
    }
  }

  formatFinalObj (obj) {
    return obj
  }

  formatNameCSV (obj) {
    return titleCase(obj.name)
  }

  formatPatientStatusCSV (obj) {
    return obj.patientStatus.replace(/(?:\\[rn]|[\r\n])/g, '')
  }

  formatAdmissionDateCSV (date) {
    const onlyDate = date.split(' ')[0]
    const d = onlyDate.length === 7 ? `0${onlyDate}` : onlyDate
    return d
  }

  formatBirthDate (date) {
    const d = date.length === 7 ? `0${date}` : date
    return d
  }

  formatGovtIdCSV () {
    return this.jsonObj.govId
  }

  async formatCSVJson () {
    const obj = { ...this.jsonObj }
    // Format object

    // Format govId
    obj.govId = this.formatGovtIdCSV()

    // Format name
    const { firstName, lastName } = this.formatNameCSV()
    obj.firstName = firstName
    obj.lastName = lastName

    // Format number
    const num = await this.formatNumberCSV()
    if (num === '') return null
    obj.number = num

    // Format email
    obj.email = obj.email.toLowerCase()

    // Format dates
    obj.admissionDate = this.formatAdmissionDateCSV(obj.admissionDate)
    obj.birthdate = this.formatBirthDate(obj.birthdate)

    // Format patient status
    obj.patientStatus = this.formatPatientStatusCSV(obj)
    return this.formatFinalObj(obj)
  }
}
