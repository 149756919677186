import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { MetroSpinner } from 'react-spinners-kit'
import { toast } from 'react-toastify'

import UsageCard from '../../cards/UsageCard/UsageCard'
import { getDateRange, numberWithCommas } from '../../../helpers/style_string.helper'
import { flowStates } from '../../../constants/flows'
import BaseTable from '../../tables/BaseTable/BaseTable'
import { getConfirmationsByClientAndDate, getConfirmationsUserByClientAndDate } from '../../../actions/confirmation'
import { sumStates } from '../../../helpers/table.helper'
import { getBotConversationCountByClientAndDate } from '../../../actions/bot'
import RangeDatePicker from '../../pickers/RangeDatePicker/RangeDatePicker'
import clients from '../../../constants/clients'
import './UsageInfo.css'

function UsageInfo () {
  const client = useSelector((state) => state.client)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const [hasCall, setHasCall] = useState(false)
  const [dates, setDates] = useState('')
  const [dateRange, setDateRange] = useState([null, null])
  const [collaboratorManagement, setCollaboratorManagement] = useState({ header: [], json: [] })
  const [states, setStates] = useState({
    sentWP: 0,
    confirmedWP: 0,
    cancelledWP: 0,
    callWP: 0,
    otherWP: 0,
    noReplyWP: 0,
    botWP: 0,
    informative: 0,
    sentCall: 0,
    confirmedCall: 0,
    cancelledCall: 0,
    callCall: 0,
    otherCall: 0,
    noReplyCall: 0,
    notAttendedWP: 0,
    rescheduledWP: 0,
    noAnswerWP: 0,
    paymentWP: 0
  })
  const [total, setTotal] = useState({
    messages: 0,
    bot: 0,
    calls: 0,
    total: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const [isThisMonth, setIsThisMonth] = useState(true)
  const trm = 5000
  let valuePerMessage = 0.025 * trm
  const valuePerCall = 0.044 * trm

  useEffect(() => {
    let isActive = true

    if (isActive) {
      setIsLoading(true)
      hasCallInFlow()
      getConfirmations()
      setIsLoading(false)
    }

    return () => {
      isActive = false
    }
  }, [dates])

  function hasCallInFlow () {
    const clientFlows = flowStates[client._id] ? flowStates[client._id] : flowStates.Default
    const clientStates = Object.values(clientFlows)
      .map(f => Object.keys(f))
      .flat()
      .filter((item, pos, self) => self.indexOf(item) === pos)
    clientStates.forEach(s => {
      const state = s.split('_')[0]
      if (state.includes('CALL')) setHasCall(true)
    })
  }

  function formatManagementCollaborator (data) {
    const header = ['Nombre', 'Gestiones']
    const json = data.map((d) => {
      return {
        Nombre: d.firstName + ' ' + d.lastName,
        Gestiones: d.count
      }
    })
    return { header, json }
  }

  function formatRowData (rowData, row, i) {
    return rowData
  }

  async function getConfirmations () {
    const d = dates || getDateRange()
    try {
      const { data } = await getConfirmationsByClientAndDate(user, token, d, client._id)
      const { data: botData } = await getBotConversationCountByClientAndDate(user, token, d, client._id)
      const { data: usersData } = await getConfirmationsUserByClientAndDate(user, token, d, client._id)
      setCollaboratorManagement(formatManagementCollaborator(usersData))
      if (data) {
        const s = sumStates(data, botData, client._id)
        setStates(s)

        let priceMessages = s.sentWP * valuePerMessage
        if (client._id === clients.DERMATOLOGICA) {
          // For Dermatologica we charge by range of messages
          const minimumWage = parseInt(process.env.REACT_APP_MINIMUM_WAGE)
          let multiplier = 0.94
          const totalSent = s.sentWP + s.botWP + s.informative
          if (totalSent > 3500 && totalSent <= 5000) {
            multiplier = 1.27
          } else if (totalSent > 5000 && totalSent <= 7500) {
            multiplier = 1.79
          } else if (totalSent > 7500) {
            multiplier = 2.23
          }
          priceMessages = minimumWage * multiplier
        } else if (!(client._id === clients.PAULINA_ZARRABE ||
            client._id === clients.CASA_DE_SONRISAS ||
            client._id === clients.KI_PLANNER) &&
          s.sentWP + botData.total > 500) {
          // We only charge after 500 messages and valuePerMessage changes
          valuePerMessage = 250
          priceMessages = (s.sentWP - 500) * valuePerMessage + 500000
        }
        const priceBot = client._id === clients.DERMATOLOGICA ? 0 : botData.total * valuePerMessage
        const priceCalls = s.sentCall * valuePerCall
        const t = hasCall
          ? priceMessages + priceCalls + priceBot
          : priceMessages + priceBot

        if (client._id === clients.DERMATOLOGICA) {
          setTotal({
            messages: numberWithCommas(priceMessages),
            bot: 0,
            calls: numberWithCommas(priceCalls),
            total: numberWithCommas(t)
          })
        } else {
          setTotal({
            messages: numberWithCommas(priceMessages),
            bot: numberWithCommas(priceBot),
            calls: numberWithCommas(priceCalls),
            total: numberWithCommas(t)
          })
        }
      }
    } catch (e) {
      toast.error('Error de carga.')
    }
  }

  function handleClick () {
    setIsThisMonth(false)
  }

  return (
    <div className='usage'>
      {window.outerWidth > 1028 && (
        <div className='row usage-title'>
          <div className='col-md-9'>
            <h1 className='information-title'>Uso de la plataforma</h1>
          </div>
          <div className='col-md-3 filter-btn'>
            {isThisMonth
              ? (
                <button
                  className='btn main-btn'
                  onClick={handleClick}
                  disabled={isLoading || Object.keys(states).length === 0}
                >
                  Este mes
                </button>
                )
              : (
                <RangeDatePicker
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  setDates={setDates}
                />
                )}
          </div>
        </div>
      )}
      <div className='usage-container'>
        <p className='usage-description'>En esta sección podrás ver el uso que le das a plataforma.</p>
        {isLoading
          ? (
            <div className='loading-container'>
              <div className='loading'>
                <div className='loading-animation'>
                  <MetroSpinner size={70} color='#304044' loading={isLoading} />
                </div>
              </div>
            </div>
            )
          : Object.keys(states).length > 0
            ? (
              <div className='row total-row'>
                <div className='col-md-9'>
                  <div className='row usage-container-row'>
                    <h5 className='usage-section-title'><i className='fas fa-comments' /> Mensajes</h5>
                    <UsageCard title='Total' value={states.sentWP} icon='fa-comments' color='total' />
                    <UsageCard
                      title='Confirmados' value={states.confirmedWP} total={states.sentWP} icon='fa-check'
                      color='confirm'
                    />
                    <UsageCard
                      title='Cancelados' value={states.cancelledWP} total={states.sentWP} icon='fa-times'
                      color='cancel'
                    />
                    <UsageCard
                      title='Contactar' value={states.callWP} total={states.sentWP} icon='fa-phone-alt'
                      color='call'
                    />
                    <UsageCard
                      title='Escritura' value={states.otherWP} total={states.sentWP} icon='fa-pencil-alt'
                      color='other'
                    />
                    <UsageCard
                      title='Ignorado' value={states.noReplyWP} total={states.sentWP} icon='fa-user-alt-slash'
                      color='ignored'
                    />
                    {clients.WAKEUP === client._id &&
                      <UsageCard
                        title='Pago' value={states.paymentWP} total={states.sentWP} icon='fa-dollar-sign'
                        color='payment'
                      />}
                    <UsageCard
                      title='No asistió' value={states.notAttendedWP} total={states.sentWP}
                      icon='fa-calendar-times' color='not-attended'
                    />
                    <UsageCard
                      title='Reagendado' value={states.rescheduledWP} total={states.sentWP}
                      icon='fa-calendar-check' color='rescheduled'
                    />
                    <UsageCard
                      title='No contestaron' value={states.noAnswerWP} total={states.sentWP}
                      icon='fa-comment-slash' color='no-answer'
                    />
                    <hr />
                    <h5 className='usage-section-title'><i className='fas fa-robot' /> Respuestas automáticas</h5>
                    <UsageCard title='Bot' value={states.botWP} icon='fa-robot' color='bot' />
                    <UsageCard title='Evaluaciones' value={states.informative} icon='fa-info' color='bot' />
                    {hasCall && (
                      <>
                        <hr />
                        <h5 className='usage-section-title'><i className='fas fa-headset' />Llamadas automáticas</h5>
                        <UsageCard title='Total' value={states.sentCall} icon='fa-headset' color='total' />
                        <UsageCard
                          title='Confirmados' value={states.confirmationsCall} total={states.sentCall}
                          icon='fa-check' color='confirm'
                        />
                        <UsageCard
                          title='Cancelados' value={states.cancellationsCall} total={states.sentCall}
                          icon='fa-times' color='cancel'
                        />
                        <UsageCard
                          title='Contactar' value={states.callCall} total={states.sentCall} icon='fa-phone-alt'
                          color='call'
                        />
                        <UsageCard
                          title='Ignorado' value={states.noReplyCall} total={states.sentCall}
                          icon='fa-user-alt-slash' color='ignored'
                        />
                      </>
                    )}
                    {collaboratorManagement.json.length > 0 && (
                      <>
                        <hr />
                        <h5 className='usage-section-title'><i className='fas fa-users' />Gestiones por usuario</h5>
                        <div className='user-management'>
                          <BaseTable
                            json={collaboratorManagement.json}
                            header={collaboratorManagement.header}
                            formatRowData={formatRowData}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {client._id === clients.DERMATOLOGICA
                  ? (
                    <div className='col-md-3 order-first order-md-last'>
                      <div className='row totals usage-container-row'>
                        <h5 className='usage-section-title'>Resumen</h5>
                        <div className='col-6 total-txt'><p>Mensajes</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.messages}</div>
                        {hasCall && (
                          <>
                            <div className='col-6 total-txt'><p>Llamadas</p></div>
                            <div className='col-6 total-value text-nowrap'>{'$ ' + total.calls}</div>
                          </>
                        )}
                        <hr />
                        <div className='col-6 total-txt total'><p>Total</p></div>
                        <div className='col-6 total-value total text-nowrap'>{'$ ' + total.total}</div>
                      </div>
                    </div>
                    )
                  : (
                      (client._id === clients.PAULINA_ZARRABE ||
                    client._id === clients.CASA_DE_SONRISAS ||
                    client._id === clients.KI_PLANNER) ||
                  states.sentWP + states.botWP > 500) && (
                    <div className='col-md-3 order-first order-md-last'>
                      <div className='row totals usage-container-row'>
                        <h5 className='usage-section-title'>Resumen</h5>
                        <div className='col-6 total-txt'><p>Mensajes</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.messages}</div>
                        <div className='col-6 total-txt'><p>Bot</p></div>
                        <div className='col-6 total-value text-nowrap'>{'$ ' + total.bot}</div>
                        {hasCall && (
                          <>
                            <div className='col-6 total-txt'><p>Llamadas</p></div>
                            <div className='col-6 total-value text-nowrap'>{'$ ' + total.calls}</div>
                          </>
                        )}
                        <hr />
                        <div className='col-6 total-txt total'><p>Total</p></div>
                        <div className='col-6 total-value total text-nowrap'>{'$ ' + total.total}</div>
                      </div>
                    </div>
                    )}
              </div>
              )
            : (
              <div>
                <p className='no-info'>No se encontró información</p>
              </div>
              )}
      </div>
    </div>
  )
}

export default UsageInfo
