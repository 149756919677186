import _ from 'lodash'
import { findDoctor } from './table.helper'

export function joinRows (arr) {
  console.log(arr)
  // Join rows where:
  // 1. The objects are exactly the same
  // 2. The nums are the same and the dates are the same, but other keys might be different
  // Do not join where:
  // 3. The nums are the same but the dates are different

  // 1. Remove objects that are exactly the same
  const uniqueObjsArr = removeDuplicateObjects(arr)
  // 2. Join the rows where the nums are the same and the dates are the same, but other keys might be different
  const numbers = uniqueObjsArr.map(j => j.number)
  const repeatedNums = _.uniq(numbers.filter((item, pos, self) => self.indexOf(item) !== pos))

  // Get the repeated dates for the repeated numbers
  const repeatedDates = []
  repeatedNums.forEach((num) => {
    const specificNumArr = uniqueObjsArr.filter((j) => j.number === num)
    const dates = specificNumArr.map(j => j.date)
    const repeatedDatesInNum = _.uniq(dates.filter((item, pos, self) => self.indexOf(item) !== pos))
    repeatedDates.push(...repeatedDatesInNum)
  })

  // const dates = uniqueObjsArr.map(j => j.date)
  // const repeatedDates = _.uniq(dates.filter((item, pos, self) => self.indexOf(item) !== pos))

  const filters = {
    number: number => repeatedNums.includes(number),
    date: date => repeatedDates.includes(date)
  }

  const repeatedArray = filterArray(uniqueObjsArr, filters)
  const notRepeatedArray = _.difference(uniqueObjsArr, repeatedArray)

  if (repeatedArray.length > 1) {
    repeatedNums.forEach((n) => {
      const repeatedNumArray = repeatedArray.filter((j) => j.number === n)
      let newObj = { ...repeatedNumArray[0] }
      for (const key in newObj) {
        if (
          key === 'name' ||
        key === 'time' ||
        key === 'userId' ||
        key === 'doctor' ||
        key === 'specialty' ||
        key === 'procedure' ||
        key === 'date'
        ) {
        // If the id, name or doc is the same for all the repeated objects, then we don't duplicate it
          if (
            repeatedNumArray.every((o) => o[key] === repeatedNumArray[0][key]) &&
          (key === 'userId' || key === 'name' || key === 'doctor' || key === 'specialty' || key === 'procedure' || key === 'date')
          ) { continue }
          newObj = { ...newObj, [key]: styleText(repeatedNumArray, key), guardian: 'Si' }
        }
      }
      notRepeatedArray.push(newObj)
    })
  } else if (repeatedArray.length === 1) {
    notRepeatedArray.push(repeatedArray[0])
  }

  return removeEmptyObjects(notRepeatedArray)
}

export function splitRows (t, doctors) {
  const filteredJson = []
  t.forEach(c => {
    if (c && (c?.time?.includes(', ') ||
      c?.name?.includes(', ') ||
      c?.doctor?.includes(', '))) {
      const nameArr = c.name.split(', ')
      const doctorArr = c.doctor.split(', ')
      const timeArr = c?.time.split(', ')
      let procedureArr = []
      if (c.procedure) procedureArr = c.procedure.split(', ')
      for (let i = 0; i < timeArr.length; i++) {
        const r = {
          ...c,
          name: nameArr.length === timeArr.length && nameArr.length > 1 ? nameArr[i] : nameArr[0],
          doctor: doctorArr.length === timeArr.length && doctorArr.length > 1 ? doctorArr[i] : doctorArr[0],
          time: timeArr[i]
        }
        r.specialty = findDoctor(doctors, r.doctor).specialty
        if (c.procedure) r.procedure = procedureArr.length === timeArr.length && procedureArr.length > 1 ? procedureArr[i] : procedureArr[0]

        filteredJson.push(r)
      }
    } else {
      filteredJson.push(c)
    }
  })
  return filteredJson
}

function styleText (arr, key) {
  return arr.map(o => o[key]).join(', ')
}

function removeDuplicateObjects (arr) {
  return arr.reduce((unique, o) => {
    if (!unique.some(obj =>
      obj.number === o.number &&
    obj.date === o.date &&
    obj.name === o.name &&
    obj.time === o.time &&
    obj.userId === o.userId &&
    obj.doctor === o.doctor
    )) {
      unique.push(o)
    }
    return unique
  }, [])
}

function filterArray (array, filters) {
  const filterKeys = Object.keys(filters)
  return array.filter(item => {
    // validates all filter criteria
    return filterKeys.every(key => {
      // ignores non-function predicates
      if (typeof filters[key] !== 'function') return true
      return filters[key](item[key])
    })
  })
}

function removeEmptyObjects (array) {
  return array.filter(element => Object.keys(element).length !== 0)
}

// function getArrayOfRepeatedKey (arr, key) {
//   const repeatedKey = arr.map(j => j[key])
//   const r = repeatedKey.filter((item, pos, self) => self.indexOf(item) !== pos)
//   return arr.filter(obj => r.includes(obj[key]))
// }
