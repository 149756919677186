import clients from './clients'

function formatDate () {
  const now = new Date()
  now.setDate(now.getDate() + 1) // Add 1 day
  const day = now.getDate()
  let month = now.getMonth() + 1
  month = month < 10 ? '0' + month : month
  const year = now.getFullYear().toString().substr(-2)
  return `${day}/${month}/${year}`
}

export const templates = {
  [clients.PAULINA_ZARRABE]: {
    json: [{
      'Nombres Completos': 'PAULINA ZARRABE',
      Celular: '3163480984',
      Fecha: formatDate(),
      Hora: '8:40',
      Odontologo: 'PAULINA ZARRABE',
      Procedimiento: 'Cita',
      Acudiente: '',
      Contactar: '',
      Laboratio: ''
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Odontologo', 'Procedimiento', 'Acudiente', 'Contactar', 'Laboratorio']
  },
  [clients.CASA_DE_SONRISAS]: {
    json: [{
      Fecha: formatDate(),
      Agenda: 'SARA MARIA TOBON',
      Hora: '8:40:00 a. m.',
      Nombre: 'TATIANA CUARTAS MARTINEZ',
      Documento: '1038415763',
      Telefono: '0',
      Celular: '3245929148',
      Observaciones: 'pte',
      Asistencia: 'SI',
      Asunto: 'Limpieza',
      Doctor: 'SARA MARIA TOBON',
      Duracion: '40',
      Confirmar: 'SI',
      Fecha_creacion: '11/05/2022',
      Mora: '0'
    }],
    header: ['Fecha', 'Agenda', 'Hora', 'Nombre', 'Documento', 'Telefono', 'Celular', 'Observaciones', 'Asistencia', 'Asunto', 'Doctor', 'Duracion', 'Confirmar', 'Fecha_creacion', 'Mora']
  },
  [clients.KI_PLANNER]: {
    json: [{
      'Nombres Completos': 'ALEICER VESGA',
      Celular: '3214946645',
      Fecha: formatDate(),
      Hora: '8:40',
      Medico: 'MANUELA GARCIA',
      Procedimiento: 'Cita',
      Acudiente: '',
      Contactar: ''
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Medico', 'Procedimiento', 'Acudiente', 'Contactar']
  },
  [clients.URO_SERVICE]: {
    json: [{
      fecha: formatDate(),
      hora: '8:40:00 a. m.',
      nombre: 'USUARIO DE PRUEBA',
      id: '100000000',
      celular: '3216439960',
      'tipo cita': 'ECOGRAFIA DE VIAS URINARIAS (RIÑONES, VEJIGA Y PROSTATA TRANSABDOMINAL)',
      doctor: 'LUIS FERNANDO RAMIREZ',
      Ubicacion: 'Edificio Q Office'
    }],
    header: ['Fecha', 'Hora', 'Nombre', 'Id', 'Celular', 'Tipo cita', 'Doctor', 'Ubicacion']
  },
  [clients.JUAN_PABLO_GONZALEZ]: {
    json: [{
      'Nombres Completos': 'MANUELA GARCIA',
      Celular: '3216439960',
      Fecha: formatDate(),
      Hora: '8:40',
      Medico: 'JUAN PABLO GONZÁLEZ',
      Procedimiento: 'Cita',
      Acudiente: 'Si',
      Contactar: ''
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Medico', 'Procedimiento', 'Acudiente', 'Contactar']
  },
  [clients.WAKEUP]: {
    json: [{
      'Nombres Completos': 'MANUELA GARCIA',
      Celular: '3216439960',
      Fecha: formatDate(),
      Hora: '8:40',
      Responsable: 'LUIS JAVIER FERNANDEZ',
      Procedimiento: 'Cita',
      Local: 'Wake Up Poblado',
      Estado: 'Reservado',
      Tipo: 'Cita'
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Responsable', 'Procedimiento', 'Local', 'Estado', 'Tipo']
  },
  [clients.DERMATOLOGICA]: {
    json: [{
      'Nombres Completos': 'PAULINA ZARRABE',
      Celular: '3216439960',
      Fecha: formatDate(),
      Hora: '8:40',
      Responsable: 'MANUELA GARCIA',
      Procedimiento: 'LIMPIEZA FACIAL',
      Categoria: 'FACIAL',
      Acudiente: '',
      Contactar: '',
      Ubicacion: 'Viva Envigado'
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Responsable', 'Procedimiento', 'Categoría', 'Acudiente', 'Contactar', 'Ubicacion']
  },
  [clients.DEFAULT]: {
    json: [{
      'Nombres Completos': 'PAULINA ZARRABE',
      Celular: '3216439960',
      Fecha: formatDate(),
      Hora: '8:40',
      Medico: 'MANUELA GARCIA',
      Procedimiento: 'Cita',
      Acudiente: '',
      Contactar: ''
    }],
    header: ['Nombres Completos', 'Celular', 'Fecha', 'Hora', 'Medico', 'Procedimiento', 'Acudiente', 'Contactar']
  }
}
