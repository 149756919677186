import { useNavigate } from 'react-router-dom'

import Footer from '../../components/Footer/Footer'
import errorImg from '../../assets/error-img.png'
import './Error.css'
import { useTitle } from '../../helpers/use_title.helper'

function Error () {
  useTitle('Ki | Error')
  const history = useNavigate()

  return (
    <div className='error-container'>
      <div className='error-content container-fluid'>

        <div className='row'>
          <div className='col-md-6'>
            <img className='error-img' src={errorImg} alt='error-img' />
          </div>
          <div className='col-md-6 error-txt'>
            <h1>
              Error 404
            </h1>
            <p>Esta página no está disponible</p>
            <button
              type='button'
              className='btn main-btn'
              onClick={() => history(-1)}
            >
              Volver
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Error
