import { Modal } from 'bootstrap'
import parse from 'html-react-parser'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import BaseTable from '../BaseTable/BaseTable'
import { cutText } from '../../../helpers/style_string.helper'
import './PatientsTable.css'
import { deletePatientInDB, getPatientsByClient, savePatientsByClient, updatePatientInDB } from '../../../actions/patient'
import CreatePatientsModal from '../../modals/CreatePatientsModal/CreatePatientsModal'

function PatientsTable () {
  const location = useLocation()

  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)

  const header = useRef([
    'Id',
    'Tipo id',
    'Nombres',
    'Apellidos',
    'Teléfono',
    'Sexo',
    'Email',
    'Opciones'
  ])
  const [patients, setPatients] = useState([])
  const [newPatient, setNewPatient] = useState({
    govtId: '',
    govtIdType: 'TI',
    firstName: '',
    lastName: '',
    number: '',
    email: '',
    gender: 'M',
    civilStatus: '',
    bloodType: '',
    insuranceProvider: '',
    birthdate: '',
    birthPlace: '',
    country: '',
    state: '',
    city: '',
    address: ''
  })

  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState('create')

  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const calledPatients = useRef(false)

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    let isActive = true
    if (isActive && !calledPatients.current) {
      calledPatients.current = true
      getPatients()
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  async function getPatients () {
    try {
      const res = await getPatientsByClient(user, client._id, token)
      if (res && res?.data && res.data.length > 0) {
        setPatients(res.data)
        setLoading(false)
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
  }

  async function savePatientsArr (patientsArr) {
    setLoading(true)
    try {
      const res = await savePatientsByClient(user, token, patientsArr)
      modalState.hide()
      if (res?.data) {
        setPatients(res.data)
        toast.success('Lista de pacientes actualizada con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  function update (row) {
    setAction('update')
    const foundPatient = patients.find((d) => d.govtId === row.govtId)
    setNewPatient(prevData => ({
      ...prevData,
      ...foundPatient
    }))
    modalState.show()
  }

  async function updatePatient (updatedPatient) {
    setLoading(true)
    try {
      const formattedPatient = { ...updatedPatient, number: updatedPatient.number.toString() }
      const res = await updatePatientInDB(user, token, formattedPatient)
      modalState.hide()
      if (res?.data) {
        setPatients(prevData => {
          return prevData.map((d) => {
            if (d._id === res.data._id) {
              return res.data
            }
            return d
          })
        })
        toast.success('Paciente actualizado con éxito.')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function deletePatient (row) {
    if (
      !window.confirm(
        '¿Estas seguro que quieres eliminar este paciente? Esta acción no se puede deshacer.'
      )
    ) { return }

    const foundPatient = patients.find((d) => d.govtId === row.govtId)
    if (foundPatient !== '') {
      try {
        const res = await deletePatientInDB(user, token, { _id: foundPatient._id })
        if (res?.data) {
          const newDoctorsArray = patients.filter((d) => d._id !== res.data._id)
          setPatients(newDoctorsArray)
          toast.success('Paciente eliminado con éxito.')
        }
      } catch (error) {
        toast.error(error.response.data.error || error.response.data.message)
      }
    }
  }

  function formatPatientsJson (resPatients) {
    const formattedPatients = []
    resPatients.forEach((d) => {
      const formattedPatient = {
        govtId: d.govtId,
        govtIdType: d.govtIdType,
        firstName: d.firstName,
        lastName: d.lastName,
        number: d.number,
        gender: d.gender,
        email: d.email
      }

      formattedPatient.options = '<button id="options"></button>'
      formattedPatients.push(formattedPatient)
    })
    return formattedPatients
  }

  function formatRowData (rowData, row, i) {
    if (location.pathname === '/profile' &&
      rowData !== null &&
      rowData !== undefined
    ) {
      if (typeof rowData === 'string' && rowData?.includes('<button')) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs.id === 'options') {
              return (
                <div className='row patient-row d-flex'>
                  <div className='col-5'>
                    <button
                      data-target='#staticBackdrop'
                      onClick={() => update(row)}
                      className='btn main-btn dr-btn text-nowrap'
                    >
                      <i className='fas fa-pen' />
                    </button>
                  </div>
                  <div className='col-5'>
                    <button
                      onClick={async () => await deletePatient(row)}
                      className='btn main-btn dr-btn text-nowrap'
                    >
                      <i className='fas fa-trash-alt' />
                    </button>
                  </div>
                </div>
              )
            }
          }
        })
      }
      if (rowData.length > 30) {
        return cutText(rowData, true, 30)
      }
    }

    return rowData
  }

  return (
    <div className='users patients'>
      {newPatient &&
        <CreatePatientsModal
          modal={modal}
          savePatientsArr={savePatientsArr}
          updatePatient={updatePatient}
          modalState={modalState}
          loading={loading}
          setLoading={setLoading}
          action={action}
          newPatient={newPatient}
          setNewPatient={setNewPatient}
          client={client}
          user={user}
        />}
      {window.outerWidth > 1028 && <h1 className='information-title'>Pacientes</h1>}
      <div className='users-container'>
        <div className='row d-flex justify-content-end'>
          <div className='col-md-9 title-col-1'>
            <h4 className='patients-num'>
              Total: {patients.length}
            </h4>
          </div>
          <div className='col-md-3 title-col-2'>
            <button
              data-target='#staticBackdrop'
              className='btn main-btn' onClick={() => {
                setNewPatient(prevData => ({
                  ...prevData,
                  name: '',
                  number: '',
                  gender: 'M',
                  govtId: '',
                  govtIdType: 'TI',
                  email: ''
                }))
                setAction('create')
                modalState.show()
              }}
            >Agregar pacientes
            </button>
          </div>
        </div>

        <BaseTable
          json={formatPatientsJson(patients)}
          header={header.current}
          formatRowData={formatRowData}
        />
      </div>
    </div>
  )
}

export default PatientsTable
