import { useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import { getPaymentSources } from '../../../actions/paymentSource'
import CardsCard from '../../cards/CardsCard/CardsCard'
import './Cards.css'

function Cards ({ cards, setCards, selectedCardId, setSelectedCardId, modalState, dispatch }) {
  const clientId = useSelector((state) => state.client?._id)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    getUserCards()
  }, [])

  async function getUserCards () {
    try {
      const { data } = await getPaymentSources(user, token, clientId)
      if (data) {
        setCards(data.cards)
        if (data.cards.length > 0) {
          const defaultCard = data.cards.filter(e => e.default)[0] || data.cards[0]
          setSelectedCardId(defaultCard._id)
          saveCardLocally(defaultCard)
        }
      }
    } catch (error) {
      toast.error('No se pudo obtener información de tus tarjetas')
    }
  }

  function saveCardLocally (c) {
    window.localStorage.setItem('card', JSON.stringify(c))
    dispatch({
      type: 'USER_CARD',
      payload: c
    })
  }

  return (
    <div className='profile-form add-card-container'>
      {window.outerWidth > 1028 && <h1 className='information-title'>Tarjetas</h1>}
      <div className='profile-form-container'>
        <div className='profile-form-header row d-flex justify-content-end'>
          {(!cards || cards.length === 0) &&
            <div className='col-md-9 title-col-1'>
              <p>Aquí aparecerán tus tarjetas guardadas.</p>
            </div>}
          <div className='add-card-btn col-md-3'>
            <button
              className='btn main-btn'
              onClick={async () => {
                modalState.show()
              }}
            >
              <i className='fas fa-credit-card' />{' '}Nueva tarjeta
            </button>
          </div>
        </div>
        {cards && cards.length > 0 &&
          <div className='cards-container row'>
            {cards.map((card, index) => (
              <div className='card-container col-md-6' key={index}>
                <CardsCard
                  card={card}
                  cards={cards}
                  setCards={setCards}
                  selectedCardId={selectedCardId}
                  setSelectedCardId={setSelectedCardId}
                  dispatch={dispatch}
                  saveCardLocally={saveCardLocally}
                />
              </div>
            ))}
          </div>}
      </div>
    </div>
  )
}

export default Cards
