import clients from '../constants/clients'
import { flowStates } from '../constants/flows'
import { roles } from '../constants/roles'
import { userStatus } from '../constants/status'
import { marketingHeaders } from '../constants/marketingHeaders'
import { wpStatus, wpStatus6Month } from '../constants/wpStatus'
import { formatDate, removeAccents } from './style_string.helper'

export function formatRowStatus (status, campaign, clientId) {
  const lastStatus = status[status.length - 1].split('-')[0]
  const rowStatus = flowStates[clientId] !== undefined
    ? flowStates[clientId][campaign]
    : flowStates.Default[campaign]
  return rowStatus[lastStatus]
}

export function wpStatuses () {
  return Object.freeze({
    sent: 'sent',
    delivered: 'delivered',
    read: 'read',
    failed: 'failed',
    rejected: 'rejected',
    confirmed: 'confirmed',
    cancelled: 'cancelled',
    call: 'call',
    other: 'other',
    no_reply: 'no_reply',
    not_attended: 'not_attended'
  })
}

export function countResponsesStatus (table, setFlowStates) {
  const s = {
    sent: 0,
    delivered: 0,
    read: 0,
    answered: 0,
    confirmed: 0,
    cancelled: 0,
    call: 0,
    other: 0,
    noReply: 0,
    payment: 0
  }
  // Get all the wp status from the table
  const wpStatusArray = table.confirmations.map(row => row.wpStatus)
  const responseStatusArray = table.confirmations.map(row => {
    const status = row.status
    return status[status.length - 1]
  })
  if (wpStatusArray.length > 0) {
    wpStatusArray.forEach(status => {
      if (status === wpStatuses().sent) s.sent += 1
      if (status === wpStatuses().delivered) s.delivered += 1
      if (status === wpStatuses().read) s.read += 1
    })
  }

  if (responseStatusArray.length > 0) {
    for (let i = 0; i < responseStatusArray.length; i++) {
      const status = responseStatusArray[i]
      if (status.includes('REPLIED')) {
        s.answered += 1
        if (status.includes('REPLIED_YES')) s.confirmed += 1
        if (status.includes('REPLIED_NO')) s.cancelled += 1
        if (status.includes('REPLIED_CALL')) {
          if (status.includes('WP1_CONV_REPLIED_CALL') && table.campaign === 'confirmation_dr') {
            s.payment += 1
          } else {
            s.call += 1
          }
        }
        if (status.includes('REPLIED_OTHER')) s.other += 1
      } else if (status.includes('NO_REPLY')) s.noReply += 1
    }
  }
  setFlowStates(s)
}

export function countResponseStatus (json, campaign, clientId, setFlowStates) {
  const s = {
    sent: 0,
    delivered: 0,
    read: 0,
    confirmed: 0,
    cancelled: 0,
    call: 0,
    other: 0,
    noReply: 0,
    notAttended: 0,
    payment: 0,
    rescheduled: 0,
    noAnswer: 0
  }
  // Get all the status from the table
  const wp = campaign === '6 month' ? wpStatus6Month : wpStatus
  json.forEach(row => {
    const { status: statusArr, wpStatus } = row
    const st = formatRowStatus(statusArr, campaign, clientId)
    const status = statusArr[statusArr.length - 1]

    if (status === 'WP1_SENT') {
      s.sent += 1
      if (wpStatus.includes(wpStatuses().delivered)) {
        s.delivered += 1
      } else if (wpStatus.includes(wpStatuses().read)) {
        s.delivered += 1
        s.read += 1
      }
    } else {
      s.sent += 1
      s.delivered += 1
      s.read += 1
      if (st === wp.YES) {
        s.confirmed += 1
      } else if (st === wp.NO) {
        s.cancelled += 1
      } else if (st === wp.CALL) {
        s.call += 1
      } else if (st === wp.OTHER) {
        s.other += 1
      } else if (st === wp.NO_REPLY) {
        s.noReply += 1
      } else if (st === wp.NOT_ATTENDED) {
        s.notAttended += 1
      } else if (st === wp.PAYMENT) {
        s.payment += 1
      } else if (st === wp.RESCHEDULED) {
        s.rescheduled += 1
      } else if (st === wp.NO_ANSWER) {
        s.noAnswer += 1
      }
    }
  })
  setFlowStates(s)
}

export function formatRole (role) {
  return roles[role]
}

export function formatStatus (st) {
  return userStatus[st]
}

export function sumStates (states, botData, clientId) {
  const statesFromDB = { ...states }

  const s = {
    sentWP: 0,
    confirmedWP: 0,
    cancelledWP: 0,
    callWP: 0,
    otherWP: 0,
    noReplyWP: 0,
    botWP: botData.botWP,
    informative: botData.informative,
    sentCall: 0,
    confirmedCall: 0,
    cancelledCall: 0,
    callCall: 0,
    otherCall: 0,
    noReplyCall: 0,
    notAttendedWP: 0,
    paymentWP: 0,
    rescheduledWP: 0,
    noAnswerWP: 0
  }

  if (flowStates[clientId].confirmation.WP1_REPLIED_NO === wpStatus.SENT) {
    delete statesFromDB.WP1_REPLIED_NO
  }

  for (const key in statesFromDB) {
    const value = statesFromDB[key]
    if (key.includes('SENT')) s.sentWP += value
    if (key.includes('REPLIED_YES')) s.confirmedWP += value
    if (key.includes('REPLIED_NO')) s.cancelledWP += value
    if (key.includes('REPLIED_CALL')) s.callWP += value
    if (key.includes('NOT_ATTENDED')) s.notAttendedWP += value
    if (key.includes('RESCHEDULED')) s.rescheduledWP += value
    if (key.includes('ANSWER')) s.noAnswerWP += value

    if (key.includes('WP1_CONV_REPLIED_CALL') && clients.WAKEUP === clientId) {
      s.paymentWP += value
    }

    if (key.includes('OTHER')) s['other' + (key.includes('WP') ? 'WP' : 'Call')] += value
    if (key.includes('NO_REPLY')) s['noReply' + (key.includes('WP') ? 'WP' : 'Call')] += value
  }
  return s
}

export function formatGuardian (guardian) {
  if (guardian === undefined || guardian === '' || guardian === null) return 'Paciente'
  return guardian
}

export function formatContact (contact) {
  if (typeof contact === 'string' && (contact === 'Si' || contact === 'No')) return contact
  if (typeof contact === 'boolean') return contact ? 'Si' : 'No'
  return 'Si'
}

export function formatState (state, is6Month) {
  const wpSt = is6Month ? wpStatus6Month : wpStatus
  if (state === 'all') return state
  if (state === 'Almacenado') {
    return 'STORED'
  } else if (state === wpSt.SENT) {
    return 'WP1_SENT'
  } else if (state === wpSt.CALL) {
    return '_REPLIED_CALL'
  } else if (state === wpSt.YES) {
    return '_REPLIED_YES'
  } else if (state === wpSt.NO) {
    return '_REPLIED_NO'
  } else if (state === wpSt.OTHER) {
    return '_REPLIED_OTHER'
  } else if (state === wpSt.SENT) {
    return '_SENT'
  } else if (state === wpSt.NOT_ATTENDED) {
    return '_NOT_ATTENDED'
  } else if (state === wpSt.RESCHEDULED) {
    return '_RESCHEDULED'
  } else if (state === wpSt.NO_ANSWER) {
    return '_NO_ANSWER'
  } else {
    return '_NO_REPLY'
  }
}

export function findDoctor (doctors, doctorName) {
  return doctors.find(dr => {
    const drName = removeAccents((dr.firstName + ' ' + dr.lastName))
      .toLowerCase()
      .replace(/\s+/g, ' ')
      .trim()
    const drName2 = removeAccents(doctorName).toLowerCase().replace(/\s+/g, ' ').trim()
    return drName === drName2 || drName2.includes(drName) || drName.includes(drName2)
  })
}

export function formatMarketingTable (patients, clientId) {
  const header = marketingHeaders[clientId]
  const jsonPatients = patients.map(patient => {
    return {
      status: 'Almacenado',
      date: formatDate(new Date(), true),
      firstName: patient.firstName,
      lastName: patient.lastName,
      number: patient.number,
      govtId: patient.govtId
    }
  })
  return { header, jsonPatients }
}

export function formatStatusValue (value, is6Month) {
  const wpSt = is6Month ? wpStatus6Month : wpStatus
  if (value.includes('_REPLIED_CALL')) {
    return wpSt.CALL
  } else if (value.includes('_REPLIED_YES')) {
    return wpSt.YES
  } else if (value.includes('_REPLIED_NO')) {
    return wpSt.NO
  } else if (value.includes('_REPLIED_OTHER')) {
    return wpSt.OTHER
  } else if (value.includes('_NO_REPLY')) {
    return wpSt.NO_REPLY
  } else if (value.includes('_SENT')) {
    return wpSt.SENT
  } else if (value.includes('_NOT_ATTENDED')) {
    return wpSt.NOT_ATTENDED
  } else if (value.includes('_RESCHEDULED')) {
    return wpSt.RESCHEDULED
  } else if (value.includes('_NO_ANSWER')) {
    return wpSt.NO_ANSWER
  }
}
