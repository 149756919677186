import { MetroSpinner } from 'react-spinners-kit'
import { useJwt } from 'react-jwt'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { saveNewPassword, hasPassword } from '../../actions/user'
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput'
import Footer from '../../components/Footer/Footer'
import { useTitle } from '../../helpers/use_title.helper'
import './ActivateUser.css'

function ActivateUser () {
  useTitle('Ki | Activar Cuenta')

  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()

  const { tokenEmail } = queryString.parse(location.search)
  const { decodedToken } = useJwt(tokenEmail)
  const [isLoading, setIsLoading] = useState(true)
  const [validToken, setValidToken] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [newUser, setNewUser] = useState({
    _id: '',
    email: '',
    password: ''
  })

  useEffect(() => {
    let isActive = true
    if (isActive && isLoading && decodedToken !== null) {
      initialize()
    }

    return function cleanup () {
      isActive = false
    }
  }, [decodedToken])

  async function initialize () {
    const { userId, user } = decodedToken
    try {
      const res = await hasPassword(userId)
      if (res.data) {
        toast.success('Este usuario ya tiene una contraseña, por favor inicia sesión.')
        history('/')
      } else {
        setNewUser((prevData) => {
          return {
            ...prevData,
            _id: userId,
            email: user
          }
        })

        setValidToken(true)
        setTimeout(() => setIsLoading(false), 1000)
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function togglePasswordVisiblity () {
    setVisiblePassword(!visiblePassword)
  }

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      if (newUser._id !== '' && newUser._id !== undefined) {
        // Save password
        if (newUser.password === '' || newUser.password.length < 6) {
          toast.error('Por favor ingresa una contraseña de al menos 6 caracteres.')
          return
        }
        const { data } = await saveNewPassword(newUser)
        const { user: u, token, client } = data
        updateLocalStorage(u, 'user', 'LOGGED_IN_USER')
        updateLocalStorage(token, 'token', 'LOGGED_IN_TOKEN')
        updateLocalStorage(client, 'client', 'LOGGED_IN_CLIENT')
        history('/main')
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='activate'>
      <div className='login-container container-fluid'>
        {isLoading
          ? (
            <div className='loading'>
              <div className='loading-animation'>
                <MetroSpinner size={70} color='#304044' loading={isLoading} />
              </div>
              <h2 className='loading-text'>Verificando...</h2>
            </div>
            )
          : validToken
            ? (
              <form className='login-form' onSubmit={handleSubmit}>
                <div className='form-title'>
                  <h2>Activa tu cuenta</h2>
                </div>
                <PasswordInput
                  visiblePassword={visiblePassword}
                  togglePasswordVisiblity={togglePasswordVisiblity}
                  handleChange={handleChange}
                  value={newUser.password}
                  name='password'
                  text='Nueva Contraseña'
                />
                <button
                  type='submit'
                  className='btn main-btn'
                >
                  Activar
                </button>
              </form>
              )
            : (
              <div className='expired'>
                <h2 className='expired-text'>¡Oh no! Tu token ha expirado.</h2>
              </div>
              )}

        <Footer />
      </div>
    </div>
  )
}

export default ActivateUser
