export const specialties = [
  'Odontología General',
  'Odontopediatría',
  'Ortodoncia',
  'Ortodoncia Interceptiva',
  'Periodoncia',
  'Endodoncia',
  'Cirugía',
  'Cirugía Maxilofacial',
  'Cirujía Oral',
  'Rehabilitación Oral',
  'Prótesis',
  'Médico General',
  'Neurología',
  'Psicología',
  'Psiquiatría',
  'Nutrición',
  'Fisioterapia',
  'Radiología',
  'Laboratorio Clínico',
  'Cardiología',
  'Ginecología',
  'Pediatría',
  'Oftalmología',
  'Otorrinolaringología',
  'Dermatología',
  'Urología',
  'Gastroenterología',
  'Neumología',
  'Reumatología',
  'Enfermería'
]
