import { combineReducers } from 'redux'

import { tableReducer } from './table'
import { tokenReducer } from './token'
import { userReducer } from './user'
import { clientReducer } from './client'
import { doctorsReducer } from './doctors'
import { cardReducer } from './card'

const rootReducer = combineReducers({
  table: tableReducer,
  user: userReducer,
  token: tokenReducer,
  client: clientReducer,
  doctors: doctorsReducer,
  card: cardReducer
})

export default rootReducer
