let clientState

if (window.localStorage.getItem('client')) {
  clientState = JSON.parse(window.localStorage.getItem('client'))
} else {
  clientState = null
}

export function clientReducer (state = clientState, action) {
  switch (action.type) {
    case 'LOGGED_IN_CLIENT':
      return { ...state, ...action.payload }

    case 'UPDATED_CLIENT':
      return { ...state, ...action.payload }

    case 'LOGOUT_CLIENT':
      return action.payload

    default:
      return state
  }
}
