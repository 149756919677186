import visa from '../assets/visa.svg'
import master from '../assets/master.svg'
import american from '../assets/american.svg'
import card from '../assets/card.svg'

export function imageSrc (src) {
  if (src === 'visa') return visa
  if (src === 'master') return master
  if (src === 'american') return american
  return card
}
