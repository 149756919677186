import React from 'react'
import RegisterUserForm from '../RegisterUserForm/RegisterUserForm'
import RolePicker from '../../pickers/RolePicker/RolePicker'
import './CreateUserForm.css'

function CreateUserForm ({ user, setUser, handleChange }) {
  function onChange (e) {
    setUser({ ...user, role: e.target.value })
  }

  return (
    <div className='form container-fluid'>
      <div className='form-floating'>
        <input
          name='email'
          type='email'
          className='form-control'
          id='floatingEmail'
          placeholder='email'
          value={user.email}
          onChange={handleChange}
          required
        />
        <label htmlFor='floatingEmail'>Email</label>
      </div>

      <RegisterUserForm
        user={user}
        handleChange={handleChange}
      />

      <div className='form-floating'>
        <RolePicker value={user.role} onChange={onChange} />
      </div>
    </div>

  )
}

export default CreateUserForm
