import './RadioBtn.css'

function RadioBtn ({ name, value, handleClick, selectedId }) {
  return (
    <label className='radio-container'>
      <input
        type='radio' name={name} value={value} onChange={handleClick} checked={
          selectedId === value
        }
      />
      <span className='radio-btn' />
    </label>
  )
}

export default RadioBtn
