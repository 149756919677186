import { CircleSpinner } from 'react-spinners-kit'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { getPresignedAcceptanceToken, saveNewCard, selectDefaultCard } from '../../../actions/paymentSource'
import AddCardForm from '../../forms/AddCardForm/AddCardForm'
import './ModalAddCard.css'

function ModalAddCard ({
  modal,
  modalState,
  setSelectedCardId,
  setCards,
  dispatch
}) {
  const clientId = useSelector((state) => state.client?._id)
  const userLogin = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)
  const email = useSelector((state) => state.client?.email)

  const [acceptanceToken, setAcceptanceToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState({
    cvc: '',
    cardNumber: '',
    expiryDate: '',
    cardName: '',
    legal: false
  })

  useEffect(() => {
    resetCardForm()
  }, [])

  function resetCardForm () {
    setUser({
      cvc: '',
      cardNumber: '',
      expiryDate: '',
      cardName: '',
      legal: false
    })
  }

  function saveCardLocally (c) {
    window.localStorage.setItem('card', JSON.stringify(c))
    dispatch({
      type: 'USER_CARD',
      payload: c
    })
  }

  async function handleSubmit (event) {
    setLoading(true)
    event.preventDefault()

    const wompiCard = {
      clientId,
      customerEmail: email,
      acceptanceToken,
      cardInfo: {
        number: user.cardNumber.replace(/\s+/g, ''),
        exp_month: user.expiryDate.split('/')[0].trim(),
        exp_year: user.expiryDate.split('/')[1].trim(),
        cvc: user.cvc,
        card_holder: user.cardName
      }
    }

    try {
      const { data } = await saveNewCard(userLogin, token, wompiCard)
      if (data) {
        setCards(data.cards)
        const defaultCard = data.cards.find((card) => card.default)
        if (!defaultCard) await selectDefaultCard(userLogin, token, clientId, '', data.cards[0]._id)
        const card = defaultCard || data.cards[0]
        card.default = true
        setSelectedCardId(card?._id)
        saveCardLocally(card)
        resetCardForm()
        modalState.hide()
        toast.success('Tarjeta guardada con éxito.')
      }
    } catch (error) {
      toast.error('Error al guardar la tarjeta, revisa tu conexión a internet y vuelve a intentarlo.')
    }
    setLoading(false)
  }

  async function handleChange (event) {
    const { name, value } = event.target

    if (name === 'legal') {
      await getToken()
      setUser((prevData) => {
        return {
          ...prevData,
          [name]: !prevData[name]
        }
      })
    } else {
      setUser((prevData) => {
        return {
          ...prevData,
          [name]: value
        }
      })
    }
  }

  async function getToken () {
    try {
      const { data } = await getPresignedAcceptanceToken()
      if (data) {
        setAcceptanceToken(data.data.presigned_acceptance.acceptance_token)
      }
    } catch (error) {
      toast.error('Error, revisa tu conexión a internet y vuelve a intentarlo.')
    }
  }

  return (
    <div
      className='modal fade card-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='modal-title'>
              <h3 className='sub-titles' id='staticBackdropLabel'>
                Agrega una tarjeta
              </h3>
              <br />
            </div>
            <button
              type='button'
              className='btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={() => {
                resetCardForm()
                modalState.hide()
              }}
            />
          </div>

          <div className='modal-body'>
            <form onSubmit={handleSubmit}>
              <AddCardForm
                user={user}
                handleChange={handleChange}
              />
              <div className='d-flex justify-content-between'>
                <button
                  type='submit'
                  disabled={!user.cardNumber || !user.cvc || !user.expiryDate || !user.cardName || !user.legal || loading}
                  className='btn primary-btn btn-orange'
                >
                  {!loading
                    ? 'Guardar'
                    : (
                      <div className='row flex loading-btn'>
                        <div className='col-3'>
                          <CircleSpinner
                            size={20}
                            color='#fff'
                            loading={loading}
                            className='spinner'
                          />
                        </div>
                        <div className='col-9'><p>Guardando...</p></div>
                      </div>)}
                </button>

                <button
                  type='button'
                  onClick={() => {
                    resetCardForm()
                    modalState.hide()
                  }}
                  className='btn primary-btn btn-blue'
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalAddCard
