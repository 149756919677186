import { Modal } from 'bootstrap'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PasswordForm from '../../components/forms/PasswordForm/PasswordForm'
import PreferencesForm from '../../components/forms/PreferencesForm/PreferencesForm'
import Cards from '../../components/components/Cards/Cards'
import Billing from '../../components/components/Billing/Billing'
import MobileHeader from '../../components/headers/MobileHeader/MobileHeader'
import UsageInfo from '../../components/components/UsageInfo/UsageInfo'
import Menu from '../../components/menus/Menu/Menu'
import ModalAddCard from '../../components/modals/ModalAddCard/ModalAddCard'
import DoctorsTable from '../../components/tables/DoctorsTable/DoctorsTable'
import UsersTable from '../../components/tables/UsersTable/UsersTable'
import PatientsTable from '../../components/tables/PatientsTable/PatientsTable'
import { useTitle } from '../../helpers/use_title.helper'
import './Profile.css'

function Profile () {
  useTitle('Ki | Perfil')
  const dispatch = useDispatch()

  const user = useSelector((state) => state.user)
  const [selectedBtn, setSelectedBtn] = useState('preferences')

  const [cards, setCards] = useState([])
  const [selectedCardId, setSelectedCardId] = useState('')
  const [modalState, setModalState] = useState(null)
  const modal = useRef()

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  function handleClick (e) {
    e.preventDefault()
    setSelectedBtn(e.target.value)
  }

  return (
    <div className='profile'>
      <ModalAddCard
        modal={modal}
        modalState={modalState}
        setSelectedCardId={setSelectedCardId}
        setCards={setCards}
        dispatch={dispatch}
      />
      <div className='main-container'>
        <div className='row main-container-row'>
          {window.outerWidth > 1028 &&
            <Menu
              selectedBtn={selectedBtn}
              handleClick={handleClick}
              user={user}
            />}
          <div className='col-md-9 information'>
            <div className='information-container'>
              {window.outerWidth < 1028 &&
                <MobileHeader
                  selectedBtn={selectedBtn}
                  handleClick={handleClick}
                  user={user}
                />}
              {selectedBtn === 'preferences' && <PreferencesForm />}
              {selectedBtn === 'users' && <UsersTable />}
              {selectedBtn === 'security' && <PasswordForm />}
              {selectedBtn === 'patients' && <PatientsTable />}
              {user.role === 'ADMIN' && selectedBtn === 'usage' && <UsageInfo />}
              {user.role === 'ADMIN' && selectedBtn === 'doctors' && <DoctorsTable />}
              {user.role === 'ADMIN' && selectedBtn === 'cards' &&
                <Cards
                  cards={cards}
                  setCards={setCards}
                  selectedCardId={selectedCardId}
                  setSelectedCardId={setSelectedCardId}
                  modalState={modalState}
                  dispatch={dispatch}
                />}
              {user.role === 'ADMIN' && selectedBtn === 'billing' &&
                <Billing
                  cards={cards}
                  setCards={setCards}
                  selectedCardId={selectedCardId}
                  setSelectedCardId={setSelectedCardId}
                  modalState={modalState}
                  dispatch={dispatch}
                />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
