let userState

if (window.localStorage.getItem('user')) {
  userState = JSON.parse(window.localStorage.getItem('user'))
} else {
  userState = null
}

export function userReducer (state = userState, action) {
  switch (action.type) {
    case 'LOGGED_IN_USER':
      return { ...state, ...action.payload }

    case 'UPDATED_USER':
      return { ...state, ...action.payload }

    case 'LOGOUT_USER':
      return action.payload

    default:
      return state
  }
}
