import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { getTableByDate } from '../../../actions/confirmation'
import { getPatientsByClient } from '../../../actions/patient'
import { getDoctorsByClient } from '../../../actions/doctor'
import { csvToJson } from '../../../helpers/excel_json.helper'
import { splitRows } from '../../../helpers/join_rows_helper'
import { formatMarketingTable } from '../../../helpers/table.helper'
import { isHistoryMoreThanXDays, orderConfirmationsByDate } from '../../../helpers/style_string.helper'
import MainSteps from '../../cards/MainSteps/MainSteps'
import './MainMenu.css'

function MainMenu ({
  fileName,
  loadingTable,
  setDateForDB,
  campaignState,
  setCampaignState,
  campaignState2,
  setCampaignState2,
  dateForDB,
  setTableState,
  reset,
  formatJson,
  setJson,
  setHeader,
  setFileName,
  isHistory,
  setLoadingTable,
  setStates,
  updateLocalStorage,
  setIsLoading
}) {
  const user = useSelector((state) => state.user)
  const client = useSelector((state) => state.client)
  const token = useSelector((state) => state.token)
  const doctors = useSelector((state) => state.doctors)
  const [searchDate, setSearchDate] = useState(new Date())

  useEffect(() => {
    if (!doctors || doctors === null || doctors?.length === 0) {
      getDoctorsFromDB()
    }
  }, [])

  useEffect(() => {
    if (campaignState === 'marketing') {
      getPatientsForMarketing()
    }
  }, [campaignState])

  async function getPatientsForMarketing () {
    const { data: patients } = await getPatientsByClient(user, client._id, token)
    if (patients.length > 0) {
      const { header, jsonPatients } = formatMarketingTable(patients, client._id)
      setFileName('Campaña Marketing')
      setStates(['Almacenado'])
      setJson(jsonPatients)
      setHeader(header)
      setTableState({ confirmations: jsonPatients })
    }
  }

  async function getDoctorsFromDB () {
    try {
      const res = await getDoctorsByClient(user, client._id, token)
      if (res.data && res.data.length > 0) {
        updateLocalStorage(res.data, 'doctors', 'NEW_DOCTORS')
      }
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
  }

  async function getTableFromDB () {
    try {
      if (dateForDB !== '') {
        const res = await getTableByDate(user, token, dateForDB, campaignState2, client._id)
        if (res.data && res.data.length > 0) {
          const tables = res.data
          const confirmations = tables.map(table => table.confirmations).flat()
          const states = Array.from(new Set(confirmations.map(s => s.status[s.status.length - 1])))
          setStates(states)
          // Create a new table with all the confirmations
          const formattedConfirmations = splitRows(orderConfirmationsByDate(confirmations), doctors)
          const newTable = { ...tables[0] }
          newTable.confirmations = formattedConfirmations
          setTableState(newTable)
          reset()
          updateLocalStorage(newTable, 'table', 'NEW_TABLE')
          // Check if history table is more than 4 days old
          const showSelectors = isHistoryMoreThanXDays(dateForDB, campaignState2)
          isHistory.current = true
          formatJson(formattedConfirmations, campaignState2, showSelectors, campaignState2 === '6 month', true)
        } else {
          toast.error('No hay tabla para esa fecha.')
        }
      } else {
        toast.error('Selecciona una fecha para ver la tabla.')
      }
    } catch (error) {
      console.log(error)
      toast.error('Ocurrió un error al cargar la tabla.')
    }
    setLoadingTable(false)
  }

  async function handleDBSearch (e) {
    e.preventDefault()
    if (campaignState2 === '') {
      toast.error('Selecciona una campaña.')
      return
    }
    setLoadingTable(true)
    setJson([])
    await getTableFromDB()
  }

  async function handleFileChange (e) {
    if (e.target.files[0]) {
      // Reset tables
      reset()
      setIsLoading(true)
      const file = e.target.files[0]
      setFileName(file.name)
      await csvToJson(e, setHeader, setJson, client, doctors, user, campaignState, setIsLoading, setStates, setTableState)
    }
  }

  function steps () {
    const stepsArr = [
      {
        text: <h5 className='step-txt'><b>Crea</b> una campaña</h5>,
        icon: 'fas fa-upload',
        btnText: 'Subir archivo'
      },
      {
        text: <h5 className='step-txt'><b>Revisa</b> el histórico</h5>,
        icon: 'fas fa-calendar-check',
        btnText: 'Buscar'
      }
    ]
    if (user.role === 'VIEWER' || window.outerWidth < 1028) return [stepsArr[1]]
    return stepsArr
  }

  return (
    <div>
      <h4 className='instructions-title'>{client.name}</h4>
      {steps().map((step, i) => {
        return (
          <MainSteps
            key={i + step.icon}
            value={i + 1}
            text={step.text}
            btnTxt={step.btnText}
            icon={step.icon}
            fileName={fileName}
            setFileName={setFileName}
            handleFileChange={handleFileChange}
            handleDBSearch={handleDBSearch}
            loadingTable={loadingTable}
            searchDate={searchDate}
            setSearchDate={setSearchDate}
            setDateForDB={setDateForDB}
            campaignState={campaignState}
            setCampaignState={setCampaignState}
            campaignState2={campaignState2}
            setCampaignState2={setCampaignState2}
          />
        )
      })}
    </div>
  )
}

export default MainMenu
