import DefaultFormat from './default_format.helper'

export default class PaulinaZarrabeFormat extends DefaultFormat {
  formatGuardianCSV () {
    const { guardian } = this.jsonObj
    const g = guardian?.toLowerCase().trim()
    if (g === undefined || g === '' || g === null || g === 'no') return 'No'
    return 'Si'
  }

  formatLab () {
    const { lab } = this.jsonObj
    const l = lab?.toLowerCase().trim()
    if (l === undefined || l === '' || l === null || l === 'no' || l === 'n') return false
    return true
  }
}
