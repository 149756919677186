import React from 'react'

function GenderPicker ({ value, onChange }) {
  return (
    <select
      className='form-control form-select'
      value={value}
      name='gender'
      onChange={onChange}
    >
      <option value='M'>M</option>
      <option value='F'>F</option>
    </select>
  )
}

export default GenderPicker
