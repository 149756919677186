export const countries = [
  { code: 'AD', country: 'Andorra' },
  { code: 'AE', country: 'Emiratos Árabes Unidos' },
  { code: 'AF', country: 'Afganistán' },
  { code: 'AG', country: 'Antigua y Barbuda' },
  { code: 'AI', country: 'Anguila' },
  { code: 'AL', country: 'Albania' },
  { code: 'AM', country: 'Armenia' },
  { code: 'AO', country: 'Angola' },
  { code: 'AQ', country: 'Antártida' },
  { code: 'AR', country: 'Argentina' },
  { code: 'AS', country: 'Samoa Americana' },
  { code: 'AT', country: 'Austria' },
  { code: 'AU', country: 'Australia' },
  { code: 'AW', country: 'Aruba' },
  { code: 'AX', country: 'Islas de Åland' },
  { code: 'AZ', country: 'Azerbaiyán' },
  { code: 'BA', country: 'Bosnia y Herzegovina' },
  { code: 'BB', country: 'Barbados' },
  { code: 'BD', country: 'Bangladesh' },
  { code: 'BE', country: 'Bélgica' },
  { code: 'BF', country: 'Burkina Faso' },
  { code: 'BG', country: 'Bulgaria' },
  { code: 'BH', country: 'Bahrein' },
  { code: 'BI', country: 'Burundi' },
  { code: 'BJ', country: 'Benín' },
  { code: 'BL', country: 'San Bartolomé' },
  { code: 'BM', country: 'Islas Bermudas' },
  { code: 'BN', country: 'Brunéi' },
  { code: 'BO', country: 'Bolivia' },
  { code: 'BQ', country: 'Bonaire, San Eustaquio y Saba' },
  { code: 'BR', country: 'Brasil' },
  { code: 'BS', country: 'Bahamas' },
  { code: 'BT', country: 'Bhután' },
  { code: 'BV', country: 'Isla Bouvet' },
  { code: 'BW', country: 'Botsuana' },
  { code: 'BY', country: 'Bielorrusia' },
  { code: 'BZ', country: 'Belice' },
  { code: 'CA', country: 'Canadá' },
  { code: 'CC', country: 'Islas Cocos (Keeling)' },
  { code: 'CD', country: 'República Democrática del Congo' },
  { code: 'CF', country: 'República Centroafricana' },
  { code: 'CG', country: 'República del Congo' },
  { code: 'CH', country: 'Suiza' },
  { code: 'CI', country: 'Costa de Marfil' },
  { code: 'CK', country: 'Islas Cook' },
  { code: 'CL', country: 'Chile' },
  { code: 'CM', country: 'Camerún' },
  { code: 'CN', country: 'China' },
  { code: 'CO', country: 'Colombia' },
  { code: 'CR', country: 'Costa Rica' },
  { code: 'CU', country: 'Cuba' },
  { code: 'CV', country: 'Cabo Verde' },
  { code: 'CW', country: 'Curazao' },
  { code: 'CX', country: 'Isla de Navidad' },
  { code: 'CY', country: 'Chipre' },
  { code: 'CZ', country: 'República Checa' },
  { code: 'DE', country: 'Alemania' },
  { code: 'DJ', country: 'Yibuti' },
  { code: 'DK', country: 'Dinamarca' },
  { code: 'DM', country: 'Dominica' },
  { code: 'DO', country: 'República Dominicana' },
  { code: 'DZ', country: 'Argelia' },
  { code: 'EC', country: 'Ecuador' },
  { code: 'EE', country: 'Estonia' },
  { code: 'EG', country: 'Egipto' },
  { code: 'EH', country: 'Sahara Occidental' },
  { code: 'ER', country: 'Eritrea' },
  { code: 'ES', country: 'España' },
  { code: 'ET', country: 'Etiopía' },
  { code: 'FI', country: 'Finlandia' },
  { code: 'FJ', country: 'Fiyi' },
  { code: 'FK', country: 'Islas Malvinas' },
  { code: 'FM', country: 'Micronesia' },
  { code: 'FO', country: 'Islas Feroe' },
  { code: 'FR', country: 'Francia' },
  { code: 'GA', country: 'Gabón' },
  { code: 'GB', country: 'Reino Unido de Gran Bretaña e Irlanda del Norte' },
  { code: 'GD', country: 'Granada' },
  { code: 'GE', country: 'Georgia' },
  { code: 'GF', country: 'Guayana Francesa' },
  { code: 'GG', country: 'Guernsey' },
  { code: 'GH', country: 'Ghana' },
  { code: 'GI', country: 'Gibraltar' },
  { code: 'GL', country: 'Groenlandia' },
  { code: 'GM', country: 'Gambia' },
  { code: 'GN', country: 'Guinea' },
  { code: 'GP', country: 'Guadalupe' },
  { code: 'GQ', country: 'Guinea Ecuatorial' },
  { code: 'GR', country: 'Grecia' },
  { code: 'GS', country: 'Islas Georgias del Sur y Sandwich del Sur' },
  { code: 'GT', country: 'Guatemala' },
  { code: 'GU', country: 'Guam' },
  { code: 'GW', country: 'Guinea-Bissau' },
  { code: 'GY', country: 'Guyana' },
  { code: 'HK', country: 'Hong kong' },
  { code: 'HM', country: 'Islas Heard y McDonald' },
  { code: 'HN', country: 'Honduras' },
  { code: 'HR', country: 'Croacia' },
  { code: 'HT', country: 'Haití' },
  { code: 'HU', country: 'Hungría' },
  { code: 'ID', country: 'Indonesia' },
  { code: 'IE', country: 'Irlanda' },
  { code: 'IL', country: 'Israel' },
  { code: 'IM', country: 'Isla de Man' },
  { code: 'IN', country: 'India' },
  { code: 'IO', country: 'Territorio Británico del Océano Índico' },
  { code: 'IQ', country: 'Irak' },
  { code: 'IR', country: 'Irán' },
  { code: 'IS', country: 'Islandia' },
  { code: 'IT', country: 'Italia' },
  { code: 'JE', country: 'Jersey' },
  { code: 'JM', country: 'Jamaica' },
  { code: 'JO', country: 'Jordania' },
  { code: 'JP', country: 'Japón' },
  { code: 'KE', country: 'Kenia' },
  { code: 'KG', country: 'Kirguistán' },
  { code: 'KH', country: 'Camboya' },
  { code: 'KI', country: 'Kiribati' },
  { code: 'KM', country: 'Comoras' },
  { code: 'KN', country: 'San Cristóbal y Nieves' },
  { code: 'KP', country: 'Corea del Norte' },
  { code: 'KR', country: 'Corea del Sur' },
  { code: 'KW', country: 'Kuwait' },
  { code: 'KY', country: 'Islas Caimán' },
  { code: 'KZ', country: 'Kazajistán' },
  { code: 'LA', country: 'Laos' },
  { code: 'LB', country: 'Líbano' },
  { code: 'LC', country: 'Santa Lucía' },
  { code: 'LI', country: 'Liechtenstein' },
  { code: 'LK', country: 'Sri lanka' },
  { code: 'LR', country: 'Liberia' },
  { code: 'LS', country: 'Lesoto' },
  { code: 'LT', country: 'Lituania' },
  { code: 'LU', country: 'Luxemburgo' },
  { code: 'LV', country: 'Letonia' },
  { code: 'LY', country: 'Libia' },
  { code: 'MA', country: 'Marruecos' },
  { code: 'MC', country: 'Mónaco' },
  { code: 'MD', country: 'Moldavia' },
  { code: 'ME', country: 'Montenegro' },
  { code: 'MF', country: 'San Martín (Francia)' },
  { code: 'MG', country: 'Madagascar' },
  { code: 'MH', country: 'Islas Marshall' },
  { code: 'MK', country: 'Macedônia' },
  { code: 'ML', country: 'Mali' },
  { code: 'MM', country: 'Birmania' },
  { code: 'MN', country: 'Mongolia' },
  { code: 'MO', country: 'Macao' },
  { code: 'MP', country: 'Islas Marianas del Norte' },
  { code: 'MQ', country: 'Martinica' },
  { code: 'MR', country: 'Mauritania' },
  { code: 'MS', country: 'Montserrat' },
  { code: 'MT', country: 'Malta' },
  { code: 'MU', country: 'Mauricio' },
  { code: 'MV', country: 'Islas Maldivas' },
  { code: 'MW', country: 'Malawi' },
  { code: 'MX', country: 'México' },
  { code: 'MY', country: 'Malasia' },
  { code: 'MZ', country: 'Mozambique' },
  { code: 'NA', country: 'Namibia' },
  { code: 'NC', country: 'Nueva Caledonia' },
  { code: 'NE', country: 'Niger' },
  { code: 'NF', country: 'Isla Norfolk' },
  { code: 'NG', country: 'Nigeria' },
  { code: 'NI', country: 'Nicaragua' },
  { code: 'NL', country: 'Países Bajos' },
  { code: 'NO', country: 'Noruega' },
  { code: 'NP', country: 'Nepal' },
  { code: 'NR', country: 'Nauru' },
  { code: 'NU', country: 'Niue' },
  { code: 'NZ', country: 'Nueva Zelanda' },
  { code: 'OM', country: 'Omán' },
  { code: 'PA', country: 'Panamá' },
  { code: 'PE', country: 'Perú' },
  { code: 'PF', country: 'Polinesia Francesa' },
  { code: 'PG', country: 'Papúa Nueva Guinea' },
  { code: 'PH', country: 'Filipinas' },
  { code: 'PK', country: 'Pakistán' },
  { code: 'PL', country: 'Polonia' },
  { code: 'PM', country: 'San Pedro y Miquelón' },
  { code: 'PN', country: 'Islas Pitcairn' },
  { code: 'PR', country: 'Puerto Rico' },
  { code: 'PS', country: 'Palestina' },
  { code: 'PT', country: 'Portugal' },
  { code: 'PW', country: 'Palau' },
  { code: 'PY', country: 'Paraguay' },
  { code: 'QA', country: 'Qatar' },
  { code: 'RE', country: 'Reunión' },
  { code: 'RO', country: 'Rumanía' },
  { code: 'RS', country: 'Serbia' },
  { code: 'RU', country: 'Rusia' },
  { code: 'RW', country: 'Ruanda' },
  { code: 'SA', country: 'Arabia Saudita' },
  { code: 'SB', country: 'Islas Salomón' },
  { code: 'SC', country: 'Seychelles' },
  { code: 'SD', country: 'Sudán' },
  { code: 'SE', country: 'Suecia' },
  { code: 'SG', country: 'Singapur' },
  { code: 'SH', country: 'Santa Elena' },
  { code: 'SI', country: 'Eslovenia' },
  { code: 'SJ', country: 'Svalbard y Jan Mayen' },
  { code: 'SK', country: 'Eslovaquia' },
  { code: 'SL', country: 'Sierra Leona' },
  { code: 'SM', country: 'San Marino' },
  { code: 'SN', country: 'Senegal' },
  { code: 'SO', country: 'Somalia' },
  { code: 'SR', country: 'Surinám' },
  { code: 'SS', country: 'República de Sudán del Sur' },
  { code: 'ST', country: 'Santo Tomé y Príncipe' },
  { code: 'SV', country: 'El Salvador' },
  { code: 'SX', country: 'Sint Maarten' },
  { code: 'SY', country: 'Siria' },
  { code: 'SZ', country: 'Swazilandia' },
  { code: 'TC', country: 'Islas Turcas y Caicos' },
  { code: 'TD', country: 'Chad' },
  { code: 'TF', country: 'Territorios Australes y Antárticas Franceses' },
  { code: 'TG', country: 'Togo' },
  { code: 'TH', country: 'Tailandia' },
  { code: 'TJ', country: 'Tayikistán' },
  { code: 'TK', country: 'Tokelau' },
  { code: 'TL', country: 'Timor Oriental' },
  { code: 'TM', country: 'Turkmenistán' },
  { code: 'TN', country: 'Tunez' },
  { code: 'TO', country: 'Tonga' },
  { code: 'TR', country: 'Turquía' },
  { code: 'TT', country: 'Trinidad y Tobago' },
  { code: 'TV', country: 'Tuvalu' },
  { code: 'TW', country: 'Taiwán' },
  { code: 'TZ', country: 'Tanzania' },
  { code: 'UA', country: 'Ucrania' },
  { code: 'UG', country: 'Uganda' },
  { code: 'UM', country: 'Islas Ultramarinas Menores de Estados Unidos' },
  { code: 'US', country: 'Estados Unidos de América' },
  { code: 'UY', country: 'Uruguay' },
  { code: 'UZ', country: 'Uzbekistán' },
  { code: 'VA', country: 'Ciudad del Vaticano' },
  { code: 'VC', country: 'San Vicente y las Granadinas' },
  { code: 'VE', country: 'Venezuela' },
  { code: 'VG', country: 'Islas Vírgenes Británicas' },
  { code: 'VI', country: 'Islas Vírgenes de los Estados Unidos' },
  { code: 'VN', country: 'Vietnam' },
  { code: 'VU', country: 'Vanuatu' },
  { code: 'WF', country: 'Wallis y Futuna' },
  { code: 'WS', country: 'Samoa' },
  { code: 'YE', country: 'Yemen' },
  { code: 'YT', country: 'Mayotte' },
  { code: 'ZA', country: 'Sudáfrica' },
  { code: 'ZM', country: 'Zambia' },
  { code: 'ZW', country: 'Zimbabue' },
  { code: 'AC', country: 'Isla Ascensión', extended: true },
  { code: 'CP', country: 'Isla Clipperton', extended: true },
  { code: 'CQ', country: 'Isla de Sark', extended: true },
  { code: 'DG', country: 'Diego García', extended: true },
  { code: 'EA', country: 'Ceuta y Melilla', extended: true },
  { code: 'EU', country: 'Unión Europea', extended: true },
  { code: 'EZ', country: 'Eurozona', extended: true },
  { code: 'FX', country: 'Francia metropolitana', extended: true },
  { code: 'IC', country: 'Islas Canarias', extended: true },
  { code: 'SU', country: 'Unión Soviética', extended: true },
  { code: 'TA', country: 'Tristán de Acuña', extended: true },
  { code: 'UK', country: 'Reino Unido', extended: true },
  { code: 'UN', country: 'Naciones Unidas', extended: true },
  { code: 'XK', country: 'Kosovo', extended: true }
]
