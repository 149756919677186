import React from 'react'

import { useTitle } from '../../helpers/use_title.helper'

function Legal () {
  useTitle('Ki | Login')

  return (
    <div>Legal</div>
  )
}

export default Legal
