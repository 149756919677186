import React from 'react'

import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import CreateDrForm from '../../forms/CreateDrForm/CreateDrForm'

function CreateDrModal ({ modal, createDr, updateDr, modalState, loading, action, newDr, setNewDr }) {
  function handleChange (event) {
    const { name, value } = event.target

    setNewDr((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      data-bs-backdrop='static'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              {action === 'create' ? 'Crea un nuevo doctor' : 'Editar doctor'}
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            <CreateDrForm doctor={newDr} setDoctor={setNewDr} handleChange={handleChange} action={action} />

            <div className='done-container'>
              <div className='center-btn'>
                {!loading
                  ? (
                    <button
                      type='button'
                      className='btn main-btn'
                      onClick={async () => {
                        if (action === 'create') {
                          await createDr(newDr, setNewDr)
                        } else {
                          await updateDr(newDr, setNewDr)
                        }
                      }}
                      disabled={
                    newDr.firstName === '' ||
                    newDr.lastName === '' ||
                    newDr.specialty === '' ||
                    newDr.doctorId === ''
                  }
                    >
                      {action === 'create' ? 'Guardar' : 'Actualizar'}
                    </button>
                    )
                  : (<LoadingBtn isLoading={loading} text='Guardando...' />)}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDrModal
