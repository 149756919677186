import React from 'react'
import GenderPicker from '../../pickers/GenderPicker/GenderPicker'
import SpecialtyPicker from '../../pickers/SpecialtyPicker/SpecialtyPicker'
import './CreateDrForm.css'

function CreateDrForm ({ doctor, setDoctor, handleChange, action }) {
  return (
    <div className='form container-fluid'>
      <div className='row d-flex user-form'>
        <div className='col-6 form-floating'>
          <input
            name='firstName'
            type='firstName'
            className='form-control'
            id='floatingFirstName'
            placeholder='Nombre'
            value={doctor.firstName}
            onChange={handleChange}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Nombre</label>
        </div>

        <div className='col-6 form-floating'>
          <input
            name='lastName'
            type='lastName'
            className='form-control'
            id='floatingLastName'
            placeholder='Apellido'
            value={doctor.lastName}
            onChange={handleChange}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Apellido</label>
        </div>

        <div className='col-3 form-floating'>
          <GenderPicker value={doctor.gender} onChange={handleChange} />
        </div>

        <div className='col-9 form-floating'>
          <input
            name='doctorId'
            type='doctorId'
            className='form-control'
            id='floatingDoctorId'
            placeholder='Registro'
            value={doctor.doctorId}
            onChange={handleChange}
            disabled={action !== 'create'}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Registro Médico</label>
        </div>
        <div className='col-12 form-floating'>
          <input
            name='email'
            type='email'
            className='form-control'
            id='floatingEmail'
            placeholder='Email'
            value={doctor.email === undefined ? '' : doctor.email}
            onChange={handleChange}
            required
          />
          <label htmlFor='floatingEmail'>Correo</label>
        </div>

        <div className='form-floating'>
          <SpecialtyPicker value={doctor.specialty} setDoctor={setDoctor} />
        </div>
      </div>
    </div>
  )
}

export default CreateDrForm
