import _ from 'lodash'

import DefaultFormat from './default_format.helper'
import { datesAreOnSameDayOrAfter, formatTimeToMilitary, monthToNumber, strToTime, titleCase, verifyNum } from '../style_string.helper'
import { findDoctor } from '../table.helper'

export default class WakeupFormat extends DefaultFormat {
  formatNameCSV (name) {
    if (this.optionalParameter.includes('AGENDA MEDICA') && name === '') return name
    if (name === undefined) {
      const { firstName, lastName } = this.jsonObj
      return titleCase((`${firstName.trim()} ${lastName.trim()}`).replace(/["]/g, ''))
    }
    return titleCase(name)
  }

  async formatNumberCSV () {
    const { number, status } = this.jsonObj
    let formattedNumber = ''
    if (number === '+5731065732') return '' // There is an error with this number
    if (this.optionalParameter.includes('AGENDA MEDICA')) {
      if (number === '') return number
      formattedNumber = ((number.split('-'))[0]).replace(/\D/gm, '').trim()
    } else {
      formattedNumber = number.replace(/[^0-9.+]/g, '').replace('+57', '').trim()
      if (formattedNumber.length === 0) return ''
    }
    try {
      if (formattedNumber !== '' && status.includes('Reservado')) return await verifyNum(formattedNumber, this.jsonObj)
      return ''
    } catch (error) {
      if (error?.response?.data !== null && parseInt(error?.response?.data.errors[0].code) === 21) {
        if (this.jsonObj.firstName === undefined || this.jsonObj.lastName === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene un número válido, pero no es un número de celular.`)
        throw new Error(`El usuario ${this.jsonObj.firstName} ${this.jsonObj.lastName} tiene un número inválido.`)
      }
      throw new Error(error.message)
    }
  }

  formatTimeCSV () {
    const { date, time } = this.jsonObj
    if (this.optionalParameter.includes('AGENDA MEDICA') && (time === undefined || time === '')) return null
    let t = time
    if (this.optionalParameter.includes('AGENDA MEDICA')) {
      if (!t.includes(':')) t = t.trim().replace(' ', ':')
      t = t
        .replace('a. m.', 'am')
        .replace('a. m.', 'am')
        .replace('p. m.', 'pm')
        .replace('p. m.', 'pm')
    }
    if (time === undefined) {
      const regex = /(\d{2}\/\d{2})\/\d{4}/g
      t = date.replace(regex, '').trim()
    }
    if (t.toLowerCase().includes('am') || t.toLowerCase().includes('pm')) t = formatTimeToMilitary(t)
    return strToTime(t.toLowerCase())
  }

  formatDateCSV () {
    if (this.optionalParameter.includes('AGENDA MEDICA')) {
      if (this.jsonObj.number === '') return null
      const arr = this.optionalParameter
        .replace('AGENDA MEDICA', '')
        .replaceAll('DE', '')
        .replaceAll(';', '')
        .replaceAll(/[\r\n]+/g, '')
        .trim()
        .split(' ')
        .filter(x => x !== '')
      const date = arr.slice(1, arr.length)
      const [d, m, y] = date
      let day = d
      if (d.length === 1 && Number(d) < 10) day = `0${d}`
      const month = monthToNumber(m)
      return `${day}/${month}/${y}`
    } else {
      let { date } = this.jsonObj
      let [d, m, y] = date.split(/\D/)
      if (d.length === 1 && Number(d) < 10) d = `0${d}`
      if (y.length !== 2) y = y.slice(2)
      date = `${d}/${m}/${y}` // Format date to DD/MM/YY
      if (date.length !== 8) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida. El formato debe ser DD/MM/YY.`)

      const today = new Date()
      const dateToCheck = new Date(y, m - 1, d)
      dateToCheck.setFullYear(dateToCheck.getFullYear() + 100) // So that the year is not 1900
      if (isNaN(dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida.`)
      if (!datesAreOnSameDayOrAfter(today, dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no puede ser en el pasado.`)
      return `${d}/${m}/${dateToCheck.getFullYear()}`
    }
  }

  formatLocationCSV () {
    try {
      const { location } = this.jsonObj
      if (this.optionalParameter.includes('AGENDA MEDICA')) {
        const l = titleCase(this.optionalParameter.split('-')[1]?.replace(/;/g, '').trim())
        const locations = ['Poblado', 'Laureles', 'Barranquilla']
        if (l === '' || !locations.includes(l)) throw new Error(`El usuario ${this.jsonObj.name} tiene una ubicación inválida.`)
        return 'Sede ' + l
      }
      const l = location.toLowerCase()
      if (location === undefined) return null
      return titleCase(l.replace('wake up', 'sede ').trim())
    } catch (error) {
      throw new Error(`El usuario ${this.jsonObj.name} tiene una ubicación inválida.`)
    }
  }

  formatStatus () {
    if (!this.optionalParameter.includes('AGENDA MEDICA') && !this.jsonObj?.status?.includes('Reservado')) return null
    return 'Almacenado'
  }

  formatDoctorAndSpecialtyCSV () {
    let dr = this.jsonObj.doctor
    if (this.optionalParameter.includes('AGENDA MEDICA')) dr = 'Luis Javier Fernandez Botero'
    const foundDr = findDoctor(this.doctors, dr)
    if (foundDr === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene como doctor un nombre incorrecto ${dr}, por favor, revisa la base de datos.`)
    const doctor = foundDr ? foundDr.firstName + ' ' + foundDr.lastName : ''
    const specialty = foundDr ? foundDr.specialty : ''
    return { doctor, specialty }
  }

  formatType () {
    const t = this.jsonObj.type.toLowerCase()
    if (t === '') return 'Cita'
    const types = Object.freeze({
      pago: 'Pago',
      pago2: 'Pago2',
      cita: 'Cita',
      'inicia/pago': 'Inicia/Pago',
      'virtual/pago': 'Virtual/Pago',
      virtual: 'Virtual'
    })

    if (Object.keys(types).includes(t)) return types[t]
    return types.cita
  }

  formatFinalObj (obj) {
    const finalObj = _.omit(obj, [
      'activeSessions',
      'createdAt',
      'responsibleCreation',
      'updatedAt',
      'internalUserId',
      'firstName',
      'lastName',
      'email',
      'userId',
      'listPrice',
      'realPrice',
      'sessionNum',
      'totalSessions',
      'paymentStatus',
      'paymentDate',
      'paymentId',
      'notes',
      'internalNotes',
      'clientPreference',
      'origin',
      'insurance',
      'maritalStatus',
      'activeSessions',
      'invoiceNum',
      'sessions28feb',
      'occupation',
      'number1',
      'howDidYouKnowUs',
      'responsibleUpdate',
      'entity'
    ])

    return Object.values(finalObj).includes(null) ? null : finalObj
  }
}
