let tableState

if (window.localStorage.getItem('table')) {
  tableState = JSON.parse(window.localStorage.getItem('table'))
} else {
  tableState = null
}

export function tableReducer (state = tableState, action) {
  switch (action.type) {
    case 'NEW_TABLE':
      return action.payload

    case 'UPDATED_TABLE':
      return { ...state, ...action.payload }

    case 'CLEAR_TABLE':
      return action.payload

    default:
      return state
  }
}
