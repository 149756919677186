import React from 'react'

import { countries } from '../../../constants/countries'

function RegisterClientForm ({ client, handleChangeV2 }) {
  return (
    <div>
      <div className='form-floating'>
        <input
          name='name'
          type='name'
          className='form-control'
          id='floatingName'
          placeholder='Nombre'
          value={client.name}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingName'>Nombre de la empresa</label>
      </div>

      <div className='form-floating'>
        <input
          name='govtId'
          type='text'
          pattern='\d*'
          maxLength='10'
          className='form-control'
          id='floatingGovtId'
          placeholder='Apellido'
          value={client.govtId}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingGovtId'>Identificación de la empresa</label>
      </div>

      <div className='form-floating'>
        <input
          name='email'
          type='email'
          className='form-control'
          id='floatingClientEmail'
          placeholder='Email'
          value={client.email}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingClientEmail'>Email de la empresa</label>
      </div>

      <div className='form-floating'>
        <select
          name='country'
          className='form-control'
          id='floatingClientCountry'
          value={client.country}
          onChange={handleChangeV2}
        >
          {countries.map((c, i) => {
            return (
              <option key={i + c} value={c.country}>{c.country}</option>
            )
          })}
        </select>
        <label htmlFor='floatingClientCountry'>País</label>
      </div>

      <div className='form-floating'>
        <input
          name='address'
          type='text'
          className='form-control'
          id='floatingClientAddress'
          placeholder='Dirección'
          value={client.address}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingClientCountry'>Dirección de la empresa</label>
      </div>

      <div className='form-floating'>
        <input
          name='number'
          type='text'
          pattern='\d*'
          className='form-control'
          id='floatingClientNumber'
          placeholder='Teléfono'
          value={client.number}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingClientCountry'>Teléfono de la empresa</label>
      </div>

      <div className='form-floating'>
        <input
          name='service'
          type='text'
          className='form-control'
          id='floatingService'
          placeholder='Servicio'
          value={client.service}
          onChange={handleChangeV2}
        />
        <label htmlFor='floatingService'>Descripción del Servicio</label>
      </div>
    </div>
  )
}

export default RegisterClientForm
