import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Outlet, Navigate } from 'react-router-dom'

import { verifyToken } from '../../actions/user'

function PrivateRoute ({ Component }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  useEffect(() => {
    let isActive = true
    if (isActive) verify()
    return () => { isActive = false }
  }, [])

  function verify () {
    if (token) {
      verifyToken(user, token).then(res => {
        if (res.data) {
          const { token } = res.data
          window.localStorage.setItem('token', JSON.stringify(token))
          dispatch({
            type: 'UPDATED_TOKEN',
            payload: token
          })
        }
      })
    }
  }

  return token ? <Outlet /> : <Navigate to='/' />
}

export default PrivateRoute
