import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Popover } from 'bootstrap'

import { templates } from '../../../constants/templates'
import './DownloadTemplateBtn.css'
import { jsonToCsv } from '../../../helpers/excel_json.helper'

function DownloadTemplateBtn () {
  const client = useSelector((state) => state.client)
  const setPopoverState = useRef()
  const popover = useRef()

  const { json, header } = templates[client._id] || templates.Default

  useEffect(() => {
    let isActive = true
    if (isActive) setPopoverState.current = new Popover(popover.current)
    return function cleanup () {
      isActive = false
    }
  }, [])

  function downloadCSV () {
    const b = jsonToCsv(json, header).replace(/"/g, '')
    // Create a blob
    // eslint-disable-next-line no-undef
    const blob = new Blob([b], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    // Create a link to download it
    const pom = document.createElement('a')
    pom.href = url
    pom.setAttribute('download', 'Plantilla')
    pom.click()
  }

  return (
    <div
      ref={popover}
      trigger='hover'
      data-bs-delay='{"show":0, "hide":100}'
      data-bs-trigger='hover'
      data-bs-placement='right'
      data-bs-toggle='popover'
      data-bs-content='Descarga una plantilla.'
    >
      <button className='download-btn' onClick={() => downloadCSV()}>
        <i className='fas fa-file-excel' />
      </button>
    </div>
  )
}

export default DownloadTemplateBtn
