import React from 'react'
import GenderPicker from '../../pickers/GenderPicker/GenderPicker'

function CreatePatientForm ({ patient, setPatient, handleChange, action }) {
  return (
    <div className='form container-fluid'>
      <div className='row d-flex user-form'>
        <div className='col-6 form-floating'>
          <input
            name='firstName'
            type='firstName'
            className='form-control'
            id='floatingFirstName'
            placeholder='Nombre'
            value={patient.firstName}
            onChange={handleChange}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Nombre</label>
        </div>

        <div className='col-6 form-floating'>
          <input
            name='lastName'
            type='lastName'
            className='form-control'
            id='floatingLastName'
            placeholder='Apellido'
            value={patient.lastName}
            onChange={handleChange}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Apellido</label>
        </div>

        <div className='col-3 form-floating'>
          <GenderPicker value={patient.gender} onChange={handleChange} />
        </div>

        <div className='col-9 form-floating'>
          <input
            name='number'
            type='number'
            className='form-control'
            id='floatingNumber'
            placeholder='Teléfono'
            value={patient.number}
            onChange={handleChange}
            maxLength='20'
            required
          />
          <label htmlFor='floatingName'>Teléfono (con código del país)</label>
        </div>

        <div className='col-12 form-floating'>
          <input
            name='email'
            type='email'
            className='form-control'
            id='floatingEmail'
            placeholder='Email'
            value={patient.email === undefined ? '' : patient.email}
            onChange={handleChange}
            required
          />
          <label htmlFor='floatingEmail'>Correo</label>
        </div>
      </div>
    </div>
  )
}

export default CreatePatientForm
