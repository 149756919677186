import {
  checkNum,
  datesAreOnSameDayOrAfter,
  removeAccents,
  strToTime,
  titleCase,
  verifyNum
} from '../style_string.helper'
import { findDoctor } from '../table.helper'

export default class DefaultFormat {
  constructor (jsonObj, doctors, campaignState, optionalParameter) {
    this.jsonObj = jsonObj
    this.doctors = doctors
    this.campaignState = campaignState
    this.optionalParameter = optionalParameter
  }

  formatDateCSV () {
    let { date } = this.jsonObj
    let [d, m, y] = date.split(/\D/)
    if (d.length === 1 && Number(d) < 10) d = `0${d}`
    if (y.length !== 2) y = y.slice(2)
    date = `${d}/${m}/${y}` // Format date to DD/MM/YY
    if (date.length !== 8) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida. El formato debe ser DD/MM/YY.`)

    const today = new Date()
    const dateToCheck = new Date(y, m - 1, d)
    dateToCheck.setFullYear(dateToCheck.getFullYear() + 100) // So that the year is not 1900
    if (isNaN(dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no es válida.`)
    if (!datesAreOnSameDayOrAfter(today, dateToCheck)) throw new Error(`La fecha de la cita con numero ${this.jsonObj.number} no puede ser en el pasado.`)
    return `${d}/${m}/${dateToCheck.getFullYear()}`
  }

  formatTimeCSV () {
    const { time } = this.jsonObj
    if (time === undefined) throw new Error('No se encontró el campo de hora.')
    if (time === '') return ''
    if (time.includes('AM') || time.includes('PM')) return time // Time is already formatted
    return strToTime(time)
  }

  async formatNumberCSV () {
    const { number } = this.jsonObj
    if (this.jsonObj?.number === undefined) throw new Error('No se encontró el campo de número.')
    if (number.length === 0) return ''
    const n = number.replaceAll(' ', '')
    try {
      if (checkNum(n)) return await verifyNum(n, this.jsonObj)
      return ''
    } catch (error) {
      if (error?.response?.data !== null && parseInt(error?.response?.data.errors[0].code) === 21) {
        if (this.jsonObj.name1 === undefined || this.jsonObj.lastName1 === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene un número válido, pero no es un número de celular.`)
        throw new Error(`El usuario ${this.jsonObj.name1} ${this.jsonObj.lastName1} tiene un número inválido.`)
      }
      throw new Error(error.message)
    }
  }

  formatDoctorAndSpecialtyCSV () {
    const { doctor: doctorName } = this.jsonObj
    const foundDr = findDoctor(this.doctors, doctorName)
    if (foundDr === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene como doctor un nombre incorrecto ${doctorName}, por favor, revisa la base de datos.`)
    const doctor = foundDr ? foundDr.firstName + ' ' + foundDr.lastName : ''
    const specialty = foundDr ? foundDr.specialty : ''
    return { doctor, specialty }
  }

  formatGuardianCSV () {
    const { guardian } = this.jsonObj
    const o = removeAccents(guardian).toLowerCase().trim()
    if (guardian === undefined ||
      guardian === '' ||
      guardian === null ||
      o === 'pte' ||
      o === 'paciente'
    ) return 'No'

    return 'Si'
  }

  formatContactCSV () {
    const { contact } = this.jsonObj
    const c = contact?.toLowerCase().trim()
    if (c === undefined ||
      c === '' ||
      c === null ||
      c === 'si'
    ) return 'Si'
    return 'No'
  }

  formatFinalObj (obj) {
    return obj
  }

  formatNameCSV (name) {
    return titleCase(name)
  }

  formatProcedureCSV (procedure) {
    return titleCase(procedure.replace(/(?:\\[rn]|[\r\n])/g, ''))
  }

  formatLocationCSV (location) {
    return titleCase(location)
  }

  formatStatus () {
    return 'Almacenado'
  }

  formatLab () {
    return this.jsonObj.lab
  }

  formatType () {
    return this.jsonObj.type
  }

  async formatCSVJson () {
    let obj = { ...this.jsonObj }
    // Format object
    obj.name = this.formatNameCSV(obj.name)
    // Format number
    const num = await this.formatNumberCSV()
    if (num === '') return null
    obj.number = num
    // Format date
    obj.date = this.formatDateCSV()
    // Format time
    obj.time = this.formatTimeCSV()
    if (obj.time === '' && (this.campaignState === '6 month' || this.campaignState === 'evaluation')) delete obj.time
    if (obj.time === '' && (this.campaignState !== '6 month' || this.campaignState === 'evaluation')) return null
    // Format procedure
    if (obj.procedure || obj.procedure === '') obj.procedure = this.formatProcedureCSV(obj.procedure)
    // Format location
    if (obj.location || obj.location === '' || Object.keys(obj).includes('location')) obj.location = this.formatLocationCSV(obj.location)
    // Format doctor and specialty
    if (Object.keys(obj).includes('doctor')) {
      const { doctor, specialty } = this.formatDoctorAndSpecialtyCSV()
      obj = { ...obj, doctor, specialty }
    }
    // Format lab
    if (Object.keys(obj).includes('lab')) obj.lab = this.formatLab()
    // Format guardian
    if (Object.keys(obj).includes('guardian')) obj.guardian = this.formatGuardianCSV()
    // Format no contact
    if (Object.keys(obj).includes('contact')) obj.contact = this.formatContactCSV()
    // Format type of appointment
    if (Object.keys(obj).includes('type')) obj.type = this.formatType()
    obj.status = this.formatStatus()

    return this.formatFinalObj(obj)
  }
}
