import axios from 'axios'

export async function getClientInvoices (clientId) {
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}accounting/invoices/${clientId}`)
}

export async function getInvoicePdf (invoiceId) {
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}accounting/invoice-pdf/${invoiceId}`)
}

export async function sendInvoiceEmail (email, invoiceId) {
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}accounting/invoice-email`, { email, invoiceId })
}
