import parse from 'html-react-parser'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { getClientInvoices, getInvoicePdf, sendInvoiceEmail } from '../../../actions/accounting'
import BaseTable from '../../tables/BaseTable/BaseTable'
import { cutText, handleNumberFormat } from '../../../helpers/style_string.helper'
import './Billing.css'
import { Modal } from 'bootstrap'
import BillingModal from '../../modals/BillingModal/BillingModal'

function Billing ({ dispatch }) {
  const location = useLocation()
  const user = useSelector((state) => state.user)
  const clientId = useSelector((state) => state.client?._id)

  const header = useRef(['Fecha', 'Factura', 'Orden', 'Tipo', 'Subtotal', 'Total', 'Estado', 'Opciones'])
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(false)
  const [invoiceData, setInvoiceData] = useState({})
  const [selectedInvoice, setSelectedInvoice] = useState({})

  const [modalState, setModalState] = useState(null)
  const modal = useRef()
  const calledInvoices = useRef(false)

  useEffect(() => {
    setModalState(new Modal(modal.current))
  }, [])

  useEffect(() => {
    let isActive = true
    if (isActive && !calledInvoices.current) {
      calledInvoices.current = true
      getInvoices()
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  async function getInvoices () {
    try {
      const { data } = await getClientInvoices(clientId)
      if (data) setInvoices(data)
    } catch (error) {
      toast.error('No se pudo obtener información de tus facturas.')
    }
  }

  function formatInvoicesJson (resInvoices) {
    const formattedInvoices = []
    // Only admins can edit doctors
    if (user.role === 'ADMIN' && !header.current.includes('Opciones')) {
      header.current.push('Opciones')
    }
    for (const i of resInvoices) {
      if (!Object.keys(i).includes('invoiceId')) return
      const date = new Date(i.invoiceDate).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
      const formattedInvoice = {
        date,
        invoiceNum: i.invoiceNum || '-',
        orderNum: '#' + i.orderNum,
        type: i.type === 'Debit' ? 'Débito' : 'Crédito',
        subtotal: handleNumberFormat(i.total.subtotal),
        total: handleNumberFormat(i.total.total),
        status: i.status
      }

      if (user.role === 'ADMIN') {
        formattedInvoice.options = '<button id="options"></button>'
      }

      formattedInvoices.push(formattedInvoice)
    }
    console.log(formattedInvoices)
    return formattedInvoices
  }

  async function downloadInvoice (row) {
    setLoading(true)
    const invoice = invoices.find((i) => row.orderNum.includes(i.orderNum))
    setSelectedInvoice(invoice)
    try {
      modalState.show()
      const { data } = await getInvoicePdf(invoice.invoiceId)
      setInvoiceData(data)
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
    setLoading(false)
  }

  async function sendInvoice (row) {
    const invoice = invoices.find((i) => row.orderNum.includes(i.orderNum))
    try {
      await sendInvoiceEmail(user.email, invoice.invoiceId)
      toast.success('Factura enviada con éxito.')
    } catch (error) {
      toast.error(error.response.data.error || error.response.data.message)
    }
  }

  function formatRowData (rowData, row, i) {
    if (user.role === 'ADMIN' &&
      location.pathname === '/profile' &&
      rowData !== null &&
      rowData !== undefined
    ) {
      if (rowData.includes('<button')) {
        return parse(rowData, {
          replace: ({ attribs }) => {
            if (attribs && attribs.id === 'options') {
              return (
                <div className='row dr-row d-flex'>
                  <div className='col-4'>
                    <button
                      data-target='#staticBackdrop'
                      onClick={() => downloadInvoice(row)}
                      className='btn main-btn dr-btn text-nowrap'
                    >
                      <i className='fas fa-download' />
                    </button>
                  </div>
                  <div className='col-4'>
                    <button
                      onClick={async () => sendInvoice(row)}
                      className='btn main-btn dr-btn text-nowrap'
                    >
                      <i className='fas fa-paper-plane' />
                    </button>
                  </div>
                </div>
              )
            }
          }
        })
      }
      if (rowData.length > 30) {
        return cutText(rowData, true, 30)
      }
    }

    return rowData
  }

  return (
    <div className='profile-form add-card-container'>
      <BillingModal
        modal={modal}
        modalState={modalState}
        invoiceData={invoiceData}
        loading={loading}
        invoiceInfo={selectedInvoice}
      />
      {window.outerWidth > 1028 && <h1 className='information-title'>Facturas</h1>}
      <div className='profile-form-container'>
        <div className='profile-form-header row d-flex justify-content-end'>
          {(!invoices || invoices.length === 0) &&
            <div className='col-md-9 title-col-1'>
              <p>Aquí aparecerán tus facturas.</p>
            </div>}
        </div>
        {invoices && invoices.length > 0 &&
          <div className='users-container'>
            <BaseTable
              json={formatInvoicesJson(invoices)}
              header={header.current}
              formatRowData={formatRowData}
            />
          </div>}
      </div>
    </div>
  )
}

export default Billing
