import React from 'react'
import { useSelector } from 'react-redux'

import { campaigns } from '../../../constants/campaigns'
import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'

function SendConfirmationModal ({ modal, modalState, campaignState, loading, startFlow, jsonLength }) {
  const client = useSelector((state) => state.client)

  function getCampaignName () {
    if (campaignState !== '') {
      let clientCampaigns = campaigns[client._id]
      if (!clientCampaigns) clientCampaigns = campaigns.Default
      return (clientCampaigns.filter(c => c.value === campaignState))[0].text
    }
    return ''
  }

  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      data-bs-backdrop='static'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              Resumen de envío
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            <p><b>{getCampaignName()}</b></p>
            <p>{jsonLength} usuario{jsonLength > 1 && 's'}</p>

            <div className='done-container'>
              <div className='center-btn'>
                {!loading
                  ? (
                    <button
                      type='button'
                      className='btn main-btn'
                      onClick={startFlow}
                    >
                      Confirmar
                    </button>
                    )
                  : (<LoadingBtn isLoading={loading} text='Enviando...' />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendConfirmationModal
