import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDate } from '../../../helpers/style_string.helper'
import './CalendarPicker.css'

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css'
registerLocale('es', es)

function CalendarPicker ({ searchDate, setSearchDate, setDateForDB, campaignDates }) {
  function blockDates (date) {
    if (campaignDates.includes(formatDate(date))) return true
  }

  return (
    <DatePicker
      dateFormat='dd/MM/yyyy'
      className='form-control date-picker'
      selected={searchDate}
      onChange={(date) => {
        setSearchDate(date)
        setDateForDB(formatDate(date))
      }}
      locale='es'
      filterDate={blockDates}
      onKeyDown={(e) => {
        e.preventDefault()
      }}
      disabled={campaignDates.length === 0}
    />
  )
}

export default CalendarPicker
