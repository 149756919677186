import React from 'react'
import { Link } from 'react-router-dom'

import './MobileHeader.css'

function MobileHeader ({ selectedBtn, handleClick, user }) {
  function defineTitle () {
    switch (selectedBtn) {
      case 'preferences':
        return 'Preferencias'
      case 'users':
        return 'Usuarios'
      case 'patients':
        return 'Pacientes'
      case 'security':
        return 'Seguridad'
      case 'cards':
        return 'Tarjetas'
      case 'billing':
        return 'Facturas'
      case 'doctors':
        return 'Doctores'
      case 'usage':
        return 'Uso'
      default:
        return 'Preferencias'
    }
  }

  return (
    <div>
      <nav className='navbar navbar-expand-lg navbar-light bg-transparent'>
        <div className='navbar-header'>
          <h1 className='information-title'>{defineTitle()}</h1>
        </div>

        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarTogglerDemo02'
          aria-controls='navbarTogglerDemo02'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>

        <div className='collapse navbar-collapse' id='navbarTogglerDemo02'>
          <div className='navbar-nav ms-auto navbar-right'>
            <>
              <li className='nav-item'>
                <Link
                  className='nav-link text-nowrap'
                  to='/'
                >
                  Inicio
                </Link>
              </li>
              <li className='nav-item'>
                <button
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarTogglerDemo02'
                  className='nav-btn text-nowrap'
                  value='preferences'
                  onClick={handleClick}
                >
                  Preferencias
                </button>
              </li>
              <li className='nav-item'>
                <button
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarTogglerDemo02'
                  className='nav-btn text-nowrap'
                  value='users'
                  onClick={handleClick}
                >
                  Usuarios
                </button>
              </li>
              <li className='nav-item'>
                <button
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarTogglerDemo02'
                  className='nav-btn text-nowrap'
                  value='security'
                  onClick={handleClick}
                >
                  Seguridad
                </button>
              </li>
              <li className='nav-item'>
                <button
                  data-bs-toggle='collapse'
                  data-bs-target='#navbarTogglerDemo02'
                  className='nav-btn text-nowrap'
                  value='patients'
                  onClick={handleClick}
                >
                  Pacientes
                </button>
              </li>
              {user.role === 'ADMIN' &&
              (
                <>
                  <li className='nav-item'>
                    <button
                      data-bs-toggle='collapse'
                      data-bs-target='#navbarTogglerDemo02'
                      className='nav-btn text-nowrap'
                      value='usage'
                      onClick={handleClick}
                    >
                      Uso
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      data-bs-toggle='collapse'
                      data-bs-target='#navbarTogglerDemo02'
                      className='nav-btn text-nowrap'
                      value='doctors'
                      onClick={handleClick}
                    >
                      Doctores
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      data-bs-toggle='collapse'
                      data-bs-target='#navbarTogglerDemo02'
                      className='nav-btn text-nowrap'
                      value='cards'
                      onClick={handleClick}
                    >
                      Tarjetas
                    </button>
                  </li>
                  <li className='nav-item'>
                    <button
                      data-bs-toggle='collapse'
                      data-bs-target='#navbarTogglerDemo02'
                      className='nav-btn text-nowrap'
                      value='billing'
                      onClick={handleClick}
                    >
                      Facturas
                    </button>
                  </li>
                </>
              )}
            </>
          </div>
        </div>
      </nav>
    </div>

  )
}

export default MobileHeader
