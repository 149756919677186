import React, { useState } from 'react'

import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'
import CreatePatientForm from '../../forms/CreatePatientForm/CreatePatientForm'
import { csvToJsonPatients } from '../../../helpers/excel_json.helper'
import { cutText } from '../../../helpers/style_string.helper'
import './CreatePatientsModal.css'

function CreatePatientsModal ({ modal, savePatientsArr, updatePatient, modalState, loading, setLoading, action, newPatient, setNewPatient, client, user }) {
  const [fileName, setFileName] = useState('')

  function handleChange (event) {
    const { name, value } = event.target

    setNewPatient((prevData) => {
      return {
        ...prevData,
        [name]: value.toString()
      }
    })
  }

  async function handleFileChange (e) {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      setFileName(file.name)
      setLoading(true)
      await csvToJsonPatients(e, savePatientsArr, client, user)
    }
  }

  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      data-bs-backdrop='static'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              {action === 'create' ? 'Agregar pacientes' : 'Editar paciente'}
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            {action === 'create'
              ? (
                <>
                  <p className='modal-text'>Sube un archivo para actualizar los pacientes</p>
                  <br />
                  <div className='update-patients-container'>
                    {fileName !== '' &&
                      <div className='drag-drop-container form-control'>
                        <div className='center'>
                          <p className='file-name'>{cutText(fileName, true, 20)}</p>
                        </div>
                      </div>}
                    {loading
                      ? (
                        <LoadingBtn isLoading={loading} text='Guardando...' />
                        )
                      : (
                        <label className='btn main-btn'>
                          <div className='row'>
                            <div className='col-2'><i className='fas fa-upload' /></div>
                            <div className='col-10'>Subir archivo</div>
                          </div>
                          <input
                            value=''
                            type='file'
                            name='excel-file'
                            onChange={handleFileChange}
                            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                            hidden
                          />
                        </label>
                        )}
                  </div>
                </>
                )
              : (
                <CreatePatientForm patient={newPatient} setPatient={setNewPatient} handleChange={handleChange} action={action} />
                )}

            {action !== 'create' && (
              <div className='done-container'>
                <div className='center-btn'>
                  {!loading
                    ? (
                      <button
                        type='button'
                        className='btn main-btn'
                        onClick={async () => {
                          await updatePatient(newPatient)
                        }}
                        disabled={
                        newPatient.firstName === '' ||
                        newPatient.lastName === '' ||
                        newPatient.number === '' ||
                        newPatient.email === '' ||
                        newPatient.govtId === '' ||
                        newPatient.govtIdType === ''
                      }
                      >
                        Actualizar
                      </button>
                      )
                    : (<LoadingBtn isLoading={loading} text='Guardando...' />)}

                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreatePatientsModal
