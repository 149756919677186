import axios from 'axios'
import { setConfig } from '../helpers/set_config.helper'

export async function getPatientsByClient (user, clientId, token) {
  const config = await setConfig(user, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}patient/${clientId}`, config)
}

export async function savePatientsByClient (user, token, patientsArr) {
  const config = await setConfig(user, token)
  return await axios.post(`${process.env.REACT_APP_CONFIRMATION_API}patient/patients`, patientsArr, config)
}

export async function updatePatientInDB (user, token, patient) {
  const config = await setConfig(user, token)
  return await axios.put(`${process.env.REACT_APP_CONFIRMATION_API}patient`, patient, config)
}

export async function deletePatientInDB (user, token, _id) {
  const config = await setConfig(user, token)
  return await axios.patch(`${process.env.REACT_APP_CONFIRMATION_API}patient`, { _id }, config)
}
