import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getDatesWithCampaign } from '../../../actions/confirmation'
import { campaigns } from '../../../constants/campaigns'
import clients from '../../../constants/clients'
import { formatDate } from '../../../helpers/style_string.helper'

function CampaignPicker ({
  campaignState,
  setCampaignState,
  resetCampaing,
  btnTxt,
  setCapaignDates,
  setSearchDate,
  setDateForDB
}) {
  const client = useSelector((state) => state.client)
  const user = useSelector((state) => state.user)
  const token = useSelector((state) => state.token)

  function renderOptionsFromClient () {
    let campaign = campaigns[client._id]
    if (!campaign) campaign = campaigns[clients.DEFAULT]
    return campaign.map((c, i) => {
      return (
        <option key={i + c.value} value={c.value}>{c.text}</option>
      )
    })
  }

  async function handleSelectorChange (e) {
    setCampaignState(e.target.value)
    resetCampaing('')
    if (btnTxt === 'Buscar') {
      try {
        const res = await getDatesWithCampaign(user, token, e.target.value, client._id)
        if (res.data && res.data.length > 0) {
          const dates = res.data
          const lastDate = new Date(dates[dates.length - 1] + 'T05:00:00.000Z')
          setSearchDate(lastDate)
          setDateForDB(formatDate(lastDate))
          setCapaignDates(dates)
          if (dates.length === 0) toast.error('No se encontraron campañas pasadas.')
        } else {
          toast.error('No se encontraron campañas pasadas.')
        }
      } catch (error) {
        toast.error('Ocurrió un error al cargar la tabla.')
      }
    }
  }

  return (
    <select
      className='form-control form-select'
      value={campaignState}
      onChange={handleSelectorChange}
    >
      <option value=''>Seleccionar campaña...</option>

      {client && renderOptionsFromClient()}
    </select>
  )
}

export default CampaignPicker
