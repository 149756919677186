import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

import './AddCardForm.css'

function AddCardForm ({ user, handleChange }) {
  const ERROR_MESSAGES = {
    emptyCardNumber: 'El número de la tarjeta es inválido',
    invalidCardNumber: 'El número de la tarjeta es inválido',
    emptyExpiryDate: 'La fecha de expiración es inválida',
    monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
    yearOutOfRange: 'El año de expiración no puede estar en el pasado',
    dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
    invalidExpiryDate: 'La fecha de expiración es inválida',
    emptyCVC: 'El código de seguridad es inválido',
    invalidCVC: 'El código de seguridad es inválido'
  }

  const {
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES
  })

  return (
    <div className='row add-card-form'>
      <div className='form-group'>
        <label className='form-label'>Número de la tarjeta</label>
        <div className='form-control card-input card-number-input'>
          <svg {...getCardImageProps({ images })} />
          <input {...getCardNumberProps({ onChange: handleChange })} value={user.cardNumber} name='cardNumber' placeholder='Número de la tarjeta' />
        </div>
      </div>
      <div className='form-group col-6'>
        <label className='form-label'>Fecha de vencimiento</label>
        <div className='form-control card-input'>
          <input {...getExpiryDateProps({ onChange: handleChange })} value={user.expiryDate} name='expiryDate' />
        </div>
      </div>
      <div className='form-group col-6'>
        <label className='form-label'>Código de seguridad</label>
        <div className='form-control card-input'>
          <input {...getCVCProps({ onChange: handleChange })} value={user.cvc} name='cvc' />
        </div>
      </div>
      <div className='form-group col-md-12'>
        <label className='form-label'>Nombre en la tarjeta</label>
        <input
          name='cardName'
          type='text'
          className='form-control rounded-input-form'
          value={user.cardName}
          placeholder='Nombre en la tarjeta'
          onChange={handleChange}
          minLength='4'
          required
        />
      </div>
      <div className='form-group col-md-12'>
        <label className='legal-checkbox checkbox-container'>
          <p>
            Acepto haber leído los{' '}
            <a
              className='text-links'
              target='_blank'
              href='https://wompi.com/assets/downloadble/TC-Usuarios-Colombia.pdf?_ga=2.169168609.1148865162.1689109714-1915727217.1689109713' rel='noreferrer'
            >
              términos y condiciones y la política de privacidad
            </a>
            {' '} para guardar esta tarjeta.
          </p>
          <input
            type='checkbox'
            value={user.legal}
            name='legal'
            checked={user.legal}
            onChange={handleChange}
          />
          <span className='checkmark' />
        </label>
      </div>
    </div>
  )
}

export default AddCardForm
