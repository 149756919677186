import { MetroSpinner } from 'react-spinners-kit'
import { useJwt } from 'react-jwt'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { saveNewPassword } from '../../actions/user'
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput'
import Footer from '../../components/Footer/Footer'
import { useTitle } from '../../helpers/use_title.helper'

function PasswordChange () {
  useTitle('Ki | Cambiar contraseña')

  const dispatch = useDispatch()
  const history = useNavigate()
  const location = useLocation()

  const { tokenEmail } = queryString.parse(location.search)
  const { decodedToken } = useJwt(tokenEmail)
  const [isLoading, setIsLoading] = useState(true)
  const [validToken, setValidToken] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [newUser, setNewUser] = useState({
    _id: '',
    email: '',
    password: ''
  })

  useEffect(() => {
    let isActive = true
    if (isActive && isLoading && decodedToken !== null) {
      // Check if the token is valid
      initialize()
    }

    return function cleanup () {
      isActive = false
    }
  }, [decodedToken])

  async function initialize () {
    const { userId, user } = decodedToken
    if (userId && user) {
      setNewUser((prevData) => {
        return {
          ...prevData,
          _id: userId,
          email: user
        }
      })

      setValidToken(true)
    } else {
      setValidToken(false)
      toast.error('¡Oh no! Tu token es inválido.')
    }
    setTimeout(() => setIsLoading(false), 1000)
  }

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function togglePasswordVisiblity () {
    setVisiblePassword(!visiblePassword)
  }

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  async function handleSubmit (e) {
    e.preventDefault()
    try {
      if (newUser._id !== '' && newUser._id !== undefined) {
        // Save password
        const { data } = await saveNewPassword(newUser)
        const { user: u, token, client } = data
        updateLocalStorage(u, 'user', 'LOGGED_IN_USER')
        updateLocalStorage(token, 'token', 'LOGGED_IN_TOKEN')
        updateLocalStorage(client, 'client', 'LOGGED_IN_CLIENT')
        toast.success('Tu contraseña fue cambiada con éxito.')
        history('/main')
      }
    } catch (error) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className='activate'>
      <div className='login-container container-fluid'>
        {isLoading
          ? (
            <div className='loading'>
              <div className='loading-animation'>
                <MetroSpinner size={70} color='#304044' loading={isLoading} />
              </div>
              <h2 className='loading-text'>Verificando...</h2>
            </div>
            )
          : validToken
            ? (
              <form className='form login-form' onSubmit={handleSubmit}>
                <div className='form-title'>
                  <h2>Cambia tu Contraseña</h2>
                </div>
                <PasswordInput
                  visiblePassword={visiblePassword}
                  togglePasswordVisiblity={togglePasswordVisiblity}
                  handleChange={handleChange}
                  value={newUser.password}
                  name='password'
                  text='Nueva Contraseña'
                />
                <button type='submit' className='btn main-btn'>Cambiar</button>
              </form>
              )
            : (
              <div className='expired'>
                <h2 className='expired-text'>¡Oh no! Tu token ha expirado.</h2>
              </div>
              )}

        <Footer />
      </div>
    </div>
  )
}

export default PasswordChange
