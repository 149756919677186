import _ from 'lodash'
import DefaultFormat from './default_format.helper'
import { checkNum, titleCase, verifyNum } from '../style_string.helper'

export default class JuanPabloGonzalezFormat extends DefaultFormat {
  formatGovtIdCSV () {
    // Remove dots from govId
    const govId = this.jsonObj.govId?.replaceAll('.', '') || ''
    return govId
  }

  formatNameCSV () {
    let firstName = this.jsonObj.firstName
    let lastName = this.jsonObj.lastName
    if (this.jsonObj?.firstName2 && this.jsonObj?.lastName2) {
      firstName = `${this.jsonObj.firstName} ${this.jsonObj.firstName2}`
      lastName = `${this.jsonObj.lastName} ${this.jsonObj.lastName2}`
    }
    return { firstName: titleCase(firstName), lastName: titleCase(lastName) }
  }

  async formatNumberCSV () {
    if (this.jsonObj?.number === undefined &&
      this.jsonObj?.phone1 === undefined &&
      this.jsonObj?.phone2 === undefined
    ) throw new Error('No se encontró el campo de número.')
    const { number, phone1, phone2 } = this.jsonObj
    const numsArr = [number, phone1, phone2].map(n => n !== '' ? n.length : 0)
    // Get the number with the most digits
    const n = [number, phone1, phone2][numsArr.indexOf(Math.max(...numsArr))]
    console.log(this.jsonObj.firstName, this.jsonObj.lastName, n)
    try {
      if (checkNum(n)) return await verifyNum(n, this.jsonObj)
      return ''
    } catch (error) {
      // if (error?.response?.data !== null && parseInt(error?.response?.data.errors[0].code) === 21) {
      //   if (this.jsonObj.name1 === undefined || this.jsonObj.lastName1 === undefined) throw new Error(`El usuario ${this.jsonObj.name} tiene un número válido, pero no es un número de celular.`)
      //   throw new Error(`El usuario ${this.jsonObj.name1} ${this.jsonObj.lastName1} tiene un número inválido.`)
      // }
      // throw new Error(error.message)
    }
  }

  formatFinalObj (obj) {
    const finalObj = _.omit(obj, [
      'birthtime',
      'cityCode',
      'currentAge',
      'departmentCode',
      'education',
      'ethnicity',
      'extension1',
      'extension2',
      'firstName2',
      'lastName2',
      'genderIdentity',
      'habeasData',
      'insuranceType',
      'licenseNum',
      'observations',
      'occupation',
      'occupationDetail',
      'patientNum',
      'race',
      'religion',
      'firstName2',
      'lastName2',
      'disabilityType',
      'observations',
      'occupation',
      'occupationDetail',
      'patientNum',
      'phone1',
      'phone2'
    ])

    return Object.values(finalObj).includes(null) ? null : finalObj
  }
}
