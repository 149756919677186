import React from 'react'
import { Link } from 'react-router-dom'

import ProfileContentBtn from '../../buttons/ProfileContentBtn/ProfileContentBtn'
import kiLogo from '../../../assets/ki-footer.svg'

function ProfileMenu ({ selectedBtn, handleClick, user }) {
  return (
    <div>
      <Link to='/main'>
        <img src={kiLogo} className='ki-logo' alt='ki-logo' />
      </Link>
      <h4 className='instructions-title'>Mi Perfil</h4>
      <ProfileContentBtn selectedBtn={selectedBtn} value='preferences' text='Preferencias' handleClick={handleClick} />
      <ProfileContentBtn selectedBtn={selectedBtn} value='users' text='Usuarios' handleClick={handleClick} />
      <ProfileContentBtn selectedBtn={selectedBtn} value='patients' text='Pacientes' handleClick={handleClick} />
      <ProfileContentBtn selectedBtn={selectedBtn} value='security' text='Seguridad' handleClick={handleClick} />
      {user.role === 'ADMIN' &&
        <>
          <ProfileContentBtn selectedBtn={selectedBtn} value='cards' text='Tarjetas' handleClick={handleClick} />
          <ProfileContentBtn selectedBtn={selectedBtn} value='billing' text='Facturas' handleClick={handleClick} />
          <ProfileContentBtn selectedBtn={selectedBtn} value='doctors' text='Doctores' handleClick={handleClick} />
          <ProfileContentBtn selectedBtn={selectedBtn} value='usage' text='Uso' handleClick={handleClick} />
        </>}
    </div>
  )
}

export default ProfileMenu
