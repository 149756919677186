import axios from 'axios'
import { setConfig } from '../helpers/set_config.helper'

export async function getDoctorsByClient (user, clientId, token) {
  const config = await setConfig(user, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}doctor/${clientId}`, config)
}

export async function getSpecificDoctorByClient (user, clientId, doctorName, token) {
  const config = await setConfig(user, token)
  return await axios.get(`${process.env.REACT_APP_CONFIRMATION_API}doctor/${clientId}/${doctorName}`, config)
}

export async function saveDoctor (user, token, doctor) {
  const config = await setConfig(user, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'doctor/create', doctor, config)
}

export async function updateDoctor (user, token, doctor) {
  const config = await setConfig(user, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'doctor/update', doctor, config)
}

export async function updateNotifyDoctor (user, token, _id, notify) {
  const config = await setConfig(user, token)
  const payload = { _id, notify }
  return await axios.put(process.env.REACT_APP_CONFIRMATION_API + 'doctor/update', payload, config)
}

export async function deleteDoctor (user, token, _id) {
  const config = await setConfig(user, token)
  return await axios.post(process.env.REACT_APP_CONFIRMATION_API + 'doctor/delete', { _id }, config)
}
