import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'

import './LoadingBtn.css'

function LoadingBtn ({ isLoading, text, className = '' }) {
  return (
    <button type='submit' className={'btn main-btn ' + className} disabled>
      <div className='row flex loading-btn main-btn-content'>
        <div className='col-2'>
          <CircleSpinner
            size={20}
            color='#fff'
            loading={isLoading}
            className='spinner'
          />
        </div>
        <div className='col-10'><p>{text}</p></div>
      </div>
    </button>
  )
}

export default LoadingBtn
