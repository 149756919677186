import React from 'react'
import { Select } from 'antd'

import { specialties } from '../../../constants/specialties'

function SpecialtyPicker ({ value, setDoctor }) {
  return (
    <Select
      mode='multiple'
      placeholder='Especialidad'
      style={{ width: '100%', textAlign: 'left', fontFamily: 'Open Sans' }}
      name='specialty'
      size='large'
      value={value.length > 0 ? value.split(', ') : []}
      onChange={(value) => {
        setDoctor((prevData) => ({ ...prevData, specialty: value.join(', ') }))
      }}
    >
      {specialties.map((s, i) => {
        return (
          <Select.Option key={i + s} value={s}>{s}</Select.Option>
        )
      })}
    </Select>
  )
}

export default SpecialtyPicker
