let tokenState

if (window.localStorage.getItem('token')) {
  tokenState = JSON.parse(window.localStorage.getItem('token'))
} else {
  tokenState = null
}

export function tokenReducer (state = tokenState, action) {
  switch (action.type) {
    case 'LOGGED_IN_TOKEN':
      return action.payload

    case 'UPDATED_TOKEN':
      return action.payload

    case 'LOGOUT_TOKEN':
      return action.payload

    default:
      return state
  }
}
