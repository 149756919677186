import clients from './clients'

export const tableRows = {
  [clients.PAULINA_ZARRABE]: {
    'nombres completos': 'name',
    celular: 'number',
    fecha: 'date',
    hora: 'time',
    odontologo: 'doctor',
    especialidad: 'specialty',
    procedimiento: 'procedure',
    acudiente: 'guardian',
    contactar: 'contact',
    laboratorio: 'lab',
    estado: 'status'
  },
  [clients.CASA_DE_SONRISAS]: {
    fecha: 'date',
    agenda: 'agenda',
    hora: 'time',
    nombre: 'name',
    documento: 'userId',
    telefono: 'number1',
    celular: 'number2',
    observaciones: 'guardian',
    asistencia: 'assistence',
    asunto: 'subject',
    doctor: 'doctor',
    duracion: 'duration',
    confirmar: 'confirm',
    fecha_creacion: 'creationDate',
    mora: 'delay',
    estado: 'status'
  },
  [clients.URO_SERVICE]: {
    hora: 'time',
    'tipo id': 'idType',
    id: 'userId',
    '1er nombre': 'name1',
    '2do nombre': 'name2',
    '1er apellido': 'lastName1',
    '2do apellido': 'lastName2',
    teléfono: 'number1',
    tel_no: 'number1',
    tel馯no: 'number1',
    extensión: 'extension',
    'extensi__th>': 'extension',
    'extensi󮼯th>': 'extension',
    celular: 'number2',
    email: 'email',
    entidad: 'entity',
    'tipo cita': 'procedure',
    'tipo atención': 'appointmentType',
    'tipo atenci__th>': 'appointmentType',
    'tipo Atenci󮼯th>': 'appointmentType',
    observaciones: 'observation',
    estado: 'status',
    'usuario que edit󼯴h>': 'editor',
    'fecha y hora asistencia': 'location',
    doctor: 'doctor',
    fecha: 'date',
    nombre: 'name',
    ubicacion: 'location'
  },
  [clients.WAKEUP]: {
    'fecha de realización': 'date',
    'fecha de creación': 'createdAt',
    'responsable creación': 'responsibleCreation',
    'fecha última modificación': 'updatedAt',
    'responsable última modificación': 'responsibleUpdate',
    local: 'location',
    'n° de cliente': 'internalUserId',
    nombre: 'firstName',
    apellido: 'lastName',
    'e-mail': 'email',
    teléfono: 'number',
    'n.º de identificación': 'userId',
    servicio: 'procedure',
    'precio lista': 'listPrice',
    'precio real': 'realPrice',
    'nº de sesión': 'sessionNum',
    'sesiones totales': 'totalSessions',
    prestador: 'doctor',
    estado: 'status',
    'estado de pago': 'paymentStatus',
    'fecha pago': 'paymentDate',
    'id pago': 'paymentId',
    'notas compartidas con cliente': 'notes',
    'comentario interno': 'type',
    tipo: 'type',
    'preferencia cliente': 'clientPreference',
    origen: 'origin',
    'empresa aseguradora': 'insurance',
    'estado civil': 'maritalStatus',
    'numero de sesiones activas': 'activeSessions',
    'nº factura': 'invoiceNum',
    'número de sesiones realizadas al 28 feb 2018': 'sessions28feb',
    'ocupación/escolaridad': 'occupation',
    'teléfono fijo': 'number1',
    '¿cómo llegó a wake up?': 'howDidYouKnowUs',
    'nombres completos': 'name',
    celular: 'number',
    fecha: 'date',
    hora: 'time',
    responsable: 'doctor',
    especialidad: 'specialty',
    procedimiento: 'procedure',
    acudiente: 'guardian',
    contactar: 'contact',
    paciente: 'name',
    telefono: 'number',
    documento: 'userId',
    'entidad por la que consulta': 'entity',
    'tipo de cita': 'procedure',
    observacion: 'observation',
    'fecha de asignación de la cita': 'date',
    doctor: 'doctor',
    ubicacion: 'location'
  },
  [clients.JUAN_PABLO_GONZALEZ]: {
    num: 'patientNum',
    'id paciente': 'govtId',
    'tipo id': 'govtIdType',
    'primer nombre': 'firstName',
    'segundo nombre': 'firstName2',
    'primer apellido': 'lastName',
    'segundo apellido': 'lastName2',
    'número carnet': 'licenseNum',
    'n˙mero carnet': 'licenseNum',
    sexo: 'gender',
    'identidad de género': 'genderIdentity',
    'identidad de gènero': 'genderIdentity',
    etnia: 'ethnicity',
    'estado civil': 'civilStatus',
    'tipo sangre': 'bloodType',
    'habeas data': 'habeasData',
    raza: 'race',
    'tipo discapacidad': 'disabilityType',
    religión: 'religion',
    religiûn: 'religion',
    'fecha ingreso': 'admissionDate',
    entidad: 'insuranceProvider',
    'tipo de aseguramiento': 'insuranceType',
    telefono1: 'phone1',
    extensión1: 'extension1',
    extensiûn1: 'extension1',
    telefono2: 'phone2',
    extensión2: 'extension2',
    extensiûn2: 'extension2',
    celular: 'number',
    email: 'email',
    dirección: 'address',
    direcciûn: 'address',
    país: 'country',
    paìs: 'country',
    'código departamento': 'departmentCode',
    'cûdigo departamento': 'departmentCode',
    departamento: 'department',
    'código municipio': 'cityCode',
    'cûdigo municipio': 'cityCode',
    municipio: 'city',
    'fecha nacimiento': 'birthdate',
    'hora nacimiento': 'birthtime',
    'edad actual': 'currentAge',
    'lugar nacimiento': 'birthPlace',
    escolaridad: 'education',
    ocupación: 'occupation',
    ocupaciûn: 'occupation',
    'detalle ocupación': 'occupationDetail',
    'detalle ocupaciûn': 'occupationDetail',
    observaciones: 'observations',
    'id médico': 'doctorId',
    'id mèdico': 'doctorId',
    médico: 'doctor',
    mèdico: 'doctor',
    'estado paciente': 'patientStatus',
    'nombres completos': 'name',
    fecha: 'date',
    hora: 'time',
    medico: 'doctor',
    especialidad: 'specialty',
    procedimiento: 'procedure',
    acudiente: 'guardian',
    contactar: 'contact',
    status: 'status'
  },
  [clients.DERMATOLOGICA]: {
    'nombres completos': 'name',
    celular: 'number',
    fecha: 'date',
    hora: 'time',
    medico: 'doctor',
    responsable: 'doctor',
    especialidad: 'specialty',
    procedimiento: 'procedure',
    categoria: 'category',
    acudiente: 'guardian',
    contactar: 'contact',
    estado: 'status',
    ubicacion: 'location'
  },
  [clients.DEFAULT]: {
    'nombres completos': 'name',
    celular: 'number',
    fecha: 'date',
    hora: 'time',
    medico: 'doctor',
    especialidad: 'specialty',
    procedimiento: 'procedure',
    acudiente: 'guardian',
    contactar: 'contact',
    estado: 'status'
  }
}
