import React from 'react'

import LoadingBtn from '../../buttons/LoadingBtn/LoadingBtn'

function BillingModal ({ modal, modalState, invoiceData, loading, invoiceInfo }) {
  return (
    <div
      className='modal fade user-modal'
      ref={modal}
      id='staticBackdrop'
      data-bs-keyboard='false'
      data-bs-backdrop='static'
      tabIndex='-1'
      aria-labelledby='staticBackdropLabel'
      aria-hidden='true'
      role='dialog'
    >
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title' id='staticBackdropLabel'>
              Descargar Factura
            </h5>
            <button
              type='button'
              className='btn-close'
              onClick={() => modalState.hide()}
            />
          </div>

          <div className='modal-body'>
            <div className='done-container'>
              <div className='center-btn'>
                {!loading
                  ? (
                    <a
                      className='btn main-btn'
                      download={'Factura ' + invoiceInfo.invoiceNum}
                      href={`data:application/pdf;base64,${invoiceData.base64}`}
                      onClick={() => modalState.hide()}
                    >
                      Descargar
                    </a>
                    )
                  : (<LoadingBtn isLoading={loading} text='Descargando...' />)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingModal
