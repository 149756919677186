import clients from './clients'

export const marketingHeaders = {
  [clients.JUAN_PABLO_GONZALEZ]: [
    'Estado',
    'Fecha',
    'Nombres',
    'Apellidos',
    'Teléfono',
    'Identificación'
    // 'doctor'
  ]
}
