function ResultCard ({ iconClass, text }) {
  return (
    <div className='col-md-4 col-6'>
      <div className='results-col'>
        <p><i className={iconClass} />{text}</p>
      </div>
    </div>
  )
}

export default ResultCard
