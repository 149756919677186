import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import BaseTableFilters from '../../components/BaseTableFilters/BaseTableFilters'
import { wpStatus, wpStatus6Month } from '../../../constants/wpStatus'
import { capitalizeFirstLetter, removeAccents } from '../../../helpers/style_string.helper'
import { formatStatusValue } from '../../../helpers/table.helper'
import './BaseTable.css'

function BaseTable ({
  json,
  header,
  formatRowData,
  filter = {},
  confirmations = [],
  setCounterUsers = (prev) => prev,
  searchText = '',
  setSearchText = (prev) => prev,
  showFilters = false
}) {
  const table = useSelector((state) => state.table)
  const [finalJson, setFinalJson] = useState([])
  const [filteredJson, setFilteredJson] = useState([])
  const [filters, setFilters] = useState({})
  const [activeColumn, setActiveColumn] = useState(null)
  const openFilter = useRef()

  useEffect(() => {
    if (json && json.length > 0 && Object.keys(filters).length === 0 && searchText === '') {
      setFilteredJson(json)
      setCounterUsers(json.map((row) => row))
      setFinalJson(json)
    } else {
      const confirmation = applyFilters(json)
      setFilteredJson(confirmation)
      setCounterUsers(confirmation)
      setFinalJson(json)
    }
  }, [json])

  useEffect(() => {
    if (Object.keys(filters).length > 0 || searchText !== '') {
      setFilteredJson(applyFilters(finalJson))
    }
  }, [filters, searchText])

  useEffect(() => {
    if (filter.baseTable === 'all') {
      setFilters({})
      setSearchText('')
      setFilteredJson(json)
    }
  }, [filter])

  useEffect(() => {
    setCounterUsers(filteredJson)
  }, [filteredJson])

  useEffect(() => {
    function handleClickOutside (evt) {
      if (openFilter.current && !openFilter.current.contains(evt?.target)) {
        setActiveColumn(null)
      }
    }

    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [openFilter.current])

  const handleDropdownToggle = (column) => {
    setActiveColumn(prevColumn => prevColumn === column ? null : column)
  }

  const handleFilterChange = (column, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value
    }))
  }

  const applyFilters = (data) => {
    const filterColumn = data?.filter((row) => {
      return Object.keys(filters).every((column) => {
        const index = header.indexOf(column)
        if (!filters[column].length) return true
        if (filters[column].includes('Seleccionar todos')) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            [column]: []
          }))
          return true
        }
        if (column === 'estado') {
          return filters[column].includes(getStatusColumn(row, index))
        }
        return filters[column].includes(Object.values(row)[index])
      })
    })
    if (searchText.length > 2) {
      return filterColumn.filter((row) => {
        return Object.values(row).some((value) => {
          if (value.toString().includes('<select')) {
            const regex = value.match(/id="([^"]*)"/)
            if (!regex) return false
            const statusArr = confirmations.find((c) => c._id === regex[1]).status
            return removeAccents(formatStatusValue(statusArr[statusArr.length - 1]))
              .toLowerCase().includes(searchText.toLowerCase())
          }
          return removeAccents(value.toString()).toLowerCase().includes(searchText.toLowerCase())
        })
      })
    }
    return filterColumn
  }

  const getStatusColumn = (row, index) => {
    const select = Object.values(row)[index].toString()
    if (!select.includes('<select')) return select
    const regex = select.match(/id="([^"]*)"/)
    if (!regex) return row
    const statusArr = confirmations.find((c) => c._id === regex[1]).status
    return formatStatusValue(statusArr[statusArr.length - 1], false)
  }

  const getUniqueValuesForColumn = (column) => {
    const index = header.indexOf(column)
    const indexOfFirstFilter = header.indexOf(Object.keys(filters)[0])
    if (indexOfFirstFilter === index) {
      // If it is the first filter, we use the original array
      const allValues = finalJson.map((row) => {
        if (column === 'estado') {
          return getStatusColumn(row, index)
        }
        return Object.values(row)[index]
      })
      allValues.unshift('Seleccionar todos')
      return [...new Set(allValues)]
    }
    const allValues = filteredJson.map((row) => {
      if (column === 'estado') {
        return getStatusColumn(row, index)
      }
      return Object.values(row)[index]
    })
    allValues.unshift('Seleccionar todos')
    return [...new Set(allValues)]
  }

  function statusColor (row, rowData) {
    const tableStatusArray = table?.confirmations?.find((c) => row?._id === c?._id)?.status
    if (!tableStatusArray) return 'black'
    const tableStatus = tableStatusArray?.[tableStatusArray.length - 1]
    if (typeof rowData === 'string') {
      if (rowData === wpStatus.YES || rowData === wpStatus6Month.YES ||
        (rowData.includes('wp-status') &&
          tableStatus?.includes('_REPLIED_YES'))) return 'confirmed'
      if (rowData === wpStatus.NO || rowData === wpStatus6Month.NO ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_REPLIED_NO')))) return 'cancelled'
      if (rowData === wpStatus.CALL ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_REPLIED_CALL')))) return 'call'
      if (rowData === wpStatus.OTHER ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_REPLIED_OTHER')))) return 'other'
      if (rowData === wpStatus.NO_REPLY ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_NO_REPLY')))) return 'no-reply'
      if (rowData === wpStatus.NOT_ATTENDED ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_NOT_ATTENDED')))) return 'not-attended'
      if (rowData === wpStatus.RESCHEDULED ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_RESCHEDULED')))) return 'rescheduled'
      if (rowData === wpStatus.NO_ANSWER ||
        (rowData.includes('wp-status') &&
          (tableStatus?.includes('_NO_ANSWER')))) return 'no-answer'
    }
    return 'black'
  }

  function noContactRow (row) {
    if (!Object.keys(row).includes('contact')) return ''
    return row?.contact === 'Si' ? '' : 'no-contact'
  }

  return (
    <div className='base-container'>
      <div className='table-container'>
        <div className='scroll'>
          <table className='table table-striped table-condensed'>
            {showFilters
              ? (
                <thead className='table-title'>
                  <tr ref={openFilter}>
                    {header.map((c, i) => (
                      <th
                        className={`${i === 0 ? 'first' : i === header.length - 1 ? 'last' : ''} filter-header`}
                        key={i + c}
                        onClick={() => handleDropdownToggle(c)}
                      >
                        <div className='header-content'>
                          {capitalizeFirstLetter(c)}
                          <i
                            className={
                            `fas fa-filter 
                            ${activeColumn === c
                              ? 'active'
                              : Object.keys(filters).includes(c) && filters[c].length > 0
                                ? 'active-filter '
                                : ''}
                            `
                          }
                          />
                        </div>
                        <BaseTableFilters
                          column={c}
                          options={getUniqueValuesForColumn(c)}
                          selectedOptions={filters[c] || []}
                          onFilterChange={handleFilterChange}
                          isOpen={activeColumn === c}
                          index={i}
                          header={header}
                          openFilter={openFilter}
                        />
                      </th>
                    ))}
                  </tr>
                </thead>
                )
              : (
                <thead className='table-title'>
                  <tr>
                    {header.map((c, i) =>
                      <th
                        className={i === 0 ? 'first' : i === header.length - 1 ? 'last' : ''}
                        key={i + c}
                      >{capitalizeFirstLetter(c)}
                      </th>)}
                  </tr>
                </thead>
                )}
            <tbody>
              {filteredJson?.map(({ _id, ...data }) => data).map((row, i) => {
                let r = filteredJson[i]
                if (row.status?.includes('<select')) {
                  const id = row.status?.match(/id="([^"]*)"/)?.[1]
                  const index = json.findIndex((c) => c._id === id)
                  r = json[index]
                }
                return (
                  <tr key={i + row} className={noContactRow(row)}>
                    {Object.values(row).map((rowData, j) => {
                      if (typeof rowData === 'boolean') rowData = rowData ? 'Si' : 'No'
                      return (
                        <td
                          key={j + rowData}
                          className={statusColor(r, rowData)}
                        >
                          {formatRowData(rowData, row, i)}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BaseTable
