import { Popover } from 'bootstrap'
import React, { useEffect, useRef } from 'react'
import './InformationPopover.css'

function InformationPopover () {
  const setPopoverState = useRef()
  const popover = useRef()

  useEffect(() => {
    let isActive = true
    if (isActive) setPopoverState.current = new Popover(popover.current)
    return function cleanup () {
      isActive = false
    }
  }, [])

  return (
    <button
      ref={popover}
      trigger='hover'
      data-bs-delay='{"show":0, "hide":100}'
      data-bs-trigger='hover'
      data-bs-placement='left'
      data-bs-toggle='popover'
      data-bs-html='true'
      data-bs-content='
      <ul>
        <li><b>Enviados:</b> Mensajes enviados a los pacientes.</li>
        <li><b>Entregados:</b> Mensajes entregados a los pacientes.</li>
        <li><b>Vistos:</b> Mensajes abiertos.</li>
        <li><b>Confirmados/Agendados:</b> Pacientes que confirmaron/agendaron su cita.</li>
        <li><b>Cancelados/No llamar:</b> Pacientes que cancelaron su cita.</li>
        <li><b>Contactar:</b> Pacientes que solicitaron que los contacte alguien.</li>
        <li><b>Ignorado:</b> Pacientes que ignoraron el mensaje y deben ser llamados.</li>
        <li><b>Escritura:</b> Pacientes que contestaron el mensaje pero este no coincide con ninguna respuesta esperada.</li>
        <li><b>No asistió:</b> Pacientes que no vinieron a la cita.</li>
        <li><b>Reagendado:</b> Pacientes que fueron reagendados.</li>
        <li><b>No contestaron:</b> Pacientes que no contestaron el mensaje.</li>
        <li><b>Pago:</b> Pacientes que solicitaron un descuento.</li>
      </ul>'
      className='info-btn'
    >
      <i className='fas fa-info' />
    </button>
  )
}

export default InformationPopover
