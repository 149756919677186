import { useLocation } from 'react-router-dom'
import ProfileBtn from '../../buttons/ProfileBtn/ProfileBtn'
import MainMenu from '../MainMenu/MainMenu'
import ProfileMenu from '../ProfileMenu/ProfileMenu'
import './Menu.css'

function Menu (props) {
  const location = useLocation()
  return (
    <div className='col-md-3 menu'>
      <div className='row instructions-container'>
        <div className='instructions'>
          {
            location.pathname === '/profile'
              ? <ProfileMenu {...props} />
              : <MainMenu {...props} />
            }
        </div>
        <ProfileBtn />
      </div>
    </div>
  )
}

export default Menu
