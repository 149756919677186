import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { toast } from 'react-toastify'

import { register, saveEmail } from '../../actions/user'
import Footer from '../../components/Footer/Footer'
import LegalCheckbox from '../../components/buttons/LegalCheckbox/LegalCheckbox'
import RegisterUserForm from '../../components/forms/RegisterUserForm/RegisterUserForm'
import RegisterClientForm from '../../components/forms/RegisterClientForm/RegisterClientForm'
import { useTitle } from '../../helpers/use_title.helper'
import './Register.css'

function Register () {
  useTitle('Ki | Registro')

  const user = useSelector((state) => state.user)
  const history = useNavigate()
  const dispatch = useDispatch()
  const [newUser, setNewUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    status: 'ACTIVE'
  })
  const [newClient, setNewClient] = useState({
    govtId: '',
    name: '',
    email: '',
    country: 'Colombia',
    address: '',
    number: '',
    service: '',
    isActive: true
  })
  const [checkbox, setCheckbox] = useState(false)
  const [visiblePassword, setVisiblePassword] = useState(false)
  const [state, setState] = useState(1)

  const nodeRef = useRef(null)

  useEffect(() => {
    let isActive = true
    if (isActive && user && user?._id) {
      history('/main')
    }
    return function cleanup () {
      isActive = false
    }
  }, [])

  function updateLocalStorage (data, objToUpdate, type) {
    window.localStorage.setItem(objToUpdate, JSON.stringify(data))
    dispatch({
      type,
      payload: data
    })
  }

  async function saveUserEmail (event) {
    event.preventDefault()
    if (checkbox) {
      try {
        const res = await saveEmail(newUser.email)
        if (res.data) setState(2)
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
        }
      }
    } else {
      toast.error(
        'Debes aceptar nuestra Política de Privacidad y Términos y Condiciones para continuar.'
      )
    }
  }

  function handleChange (event) {
    const { name, value } = event.target

    setNewUser((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function handleChangeV2 (event) {
    const { name, value } = event.target

    setNewClient((prevData) => {
      return {
        ...prevData,
        [name]: value
      }
    })
  }

  function handleCheckboxClick () {
    setCheckbox(!checkbox)
  }

  function togglePasswordVisiblity () {
    setVisiblePassword(!visiblePassword)
  }

  async function handleSubmit (e) {
    e.preventDefault()
    if (state === 2) setState(3)
    if (state === 3) {
      try {
        const res = await register(newUser, newClient)
        if (res.data) {
          const { user: u, token, client } = res.data
          updateLocalStorage(u, 'user', 'LOGGED_IN_USER')
          updateLocalStorage(token, 'token', 'LOGGED_IN_TOKEN')
          updateLocalStorage(client, 'client', 'LOGGED_IN_CLIENT')
          history('/main')
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) toast.error(error.response.data.error || error.response.data.message)
        }
      }
    }
  }

  return (
    <div className='login'>
      <SwitchTransition mode='out-in'>
        <CSSTransition
          key={state}
          nodeRef={nodeRef} in timeout={200}
          classNames='fade'
        >
          <div className='login-container container-fluid'>
            <div className='form login-form'>
              <div className='form-title'>
                <h2>Regístrate</h2>
                {state === 1 && (
                  <p>
                    ¿Ya tienes cuenta?
                    <Link
                      className='login-link text-nowrap'
                      to='/'
                    >
                      {' '}Inicia Sesión
                    </Link>
                  </p>
                )}
              </div>
              {state === 1 && (
                <form onSubmit={saveUserEmail}>
                  <div className='signin-step-1'>
                    <p className='step-info'>Paso 1 de 3</p>
                    <div className='form-floating'>
                      <input
                        name='email'
                        type='email'
                        className='form-control'
                        id='floatingEmail'
                        placeholder='email'
                        value={newUser.email}
                        onChange={handleChange}
                      />
                      <label htmlFor='floatingEmail'>Email</label>
                    </div>
                    <LegalCheckbox checkbox={checkbox} handleCheckboxClick={handleCheckboxClick} />

                    <div className='form-btn'>
                      <button disabled={!newUser.email} className='btn main-btn'>
                        Continuar
                      </button>
                    </div>

                    <p>
                      <Link
                        className='password-link text-nowrap'
                        to='/password-recovery'
                      >
                        ¿Olvidaste tu contraseña?
                      </Link>
                    </p>
                  </div>
                </form>
              )}

              {state === 2 && (
                <form onSubmit={handleSubmit}>
                  <div className='signin-step-2'>
                    <p className='step-info'>Paso 2 de 3 - Información del Administrador</p>
                    <RegisterUserForm
                      user={newUser}
                      visiblePassword={visiblePassword}
                      togglePasswordVisiblity={togglePasswordVisiblity}
                      handleChange={handleChange}
                    />
                    <button type='submit' className='btn main-btn'>Continuar</button>
                  </div>
                </form>
              )}

              {state === 3 && (
                <form onSubmit={handleSubmit}>
                  <div className='signin-step-2'>
                    <p className='step-info'>Paso 3 de 3 - Información de la Empresa</p>
                    <RegisterClientForm client={newClient} handleChangeV2={handleChangeV2} />
                    <button type='submit' className='btn main-btn'>Crear Cuenta</button>
                  </div>
                </form>
              )}
            </div>

          </div>
        </CSSTransition>
      </SwitchTransition>
      <Footer />
    </div>

  )
}

export default Register
