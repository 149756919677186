import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'

import ActivateUser from './screens/ActivateUser/ActivateUser'
import Error from './screens/Error/Error'
import Legal from './screens/Legal/Legal'
import Login from './screens/Login/Login'
import Main from './screens/Main/Main'
import PasswordChange from './screens/PasswordChange/PasswordChange'
import PasswordRecovery from './screens/PasswordRecovery/PasswordRecovery'
import Profile from './screens/Profile/Profile'
import Register from './screens/Register/Register'

function App () {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/password-recovery' element={<PasswordRecovery />} />
        <Route element={<PrivateRoute />}>
          <Route exact path='/main' element={<Main />} />
        </Route>
        <Route element={<PrivateRoute />}>
          <Route exact path='/profile' element={<Profile />} />
        </Route>
        <Route path='/legal' element={<Legal />} />
        <Route path='/activate' element={<ActivateUser />} />
        <Route path='/change-password' element={<PasswordChange />} />
        <Route exact path='*' element={<Error />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
