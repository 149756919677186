import React from 'react'
import { useLocation } from 'react-router-dom'
import PasswordInput from '../../inputs/PasswordInput/PasswordInput'
import './RegisterUserForm.css'

function RegisterUserForm ({ user, visiblePassword, togglePasswordVisiblity, handleChange }) {
  const location = useLocation()

  return (
    <>
      <div className='row d-flex user-form'>
        <div className='col-6 form-floating'>
          <input
            name='firstName'
            type='firstName'
            className='form-control'
            id='floatingFirstName'
            placeholder='Nombre'
            value={user.firstName}
            onChange={handleChange}
            required
          />
          <label htmlFor='floatingName'>Nombre</label>
        </div>
        <div className='col-6 form-floating'>
          <input
            name='lastName'
            type='lastName'
            className='form-control'
            id='floatingLastName'
            placeholder='Apellido'
            value={user.lastName}
            onChange={handleChange}
            required
          />
          <label htmlFor='floatingEmail'>Apellido</label>
        </div>
      </div>
      {location.pathname === '/register' && (
        <PasswordInput
          visiblePassword={visiblePassword}
          togglePasswordVisiblity={togglePasswordVisiblity}
          handleChange={handleChange}
          value={user.password}
          name='password'
          text='Contraseña'
        />
      )}
    </>
  )
}

export default RegisterUserForm
